import cx from 'classnames';
import { trackElement } from '@fff-web/fff-analytics';
import useFooterContent from '../../hooks/useFooterContent';
import { LegalItem } from '../../services/types';
import { buildPath } from '../../utils/buildPath';
import FFFLogo from '../../images/logo.png';
import './LegalFooter.scss';

const LEGAL_ID = 'l-0';

interface Props {
  legals: LegalItem[];
  isLoggedIn: boolean;
}
declare global {
  interface Window {
    OneTrust: unknown;
  }
}

const allowedStates = ['CA', 'CO', 'TX', 'VA', 'NE'];

const LegalFooter = ({ legals, isLoggedIn }: Props) => {
  const state = window?.OneTrust?.getGeolocationData()?.state || '';
  const { defaultYear } = useFooterContent();
  const footerLinkStyles = cx({
    pointer: legals.find((x) => x.id !== LEGAL_ID),
  });

  const clickListener = (buildedPath: string, trakingName?: string) => {
    if (trakingName) {
      trackElement({
        elementName: trakingName,
        callback: () => window.location.replace(buildedPath),
      });
    }
  };
  const createLegalLinkItem = (legal: LegalItem, index: number) => {
    const path = buildPath(legal.route ?? '', legal.subdomain, legal.prodOnly);
    return legal.route ? (
      <a
        key={legal.id}
        className={`legal-footer-items order-${index} ${footerLinkStyles}`}
        href={path}
        onKeyDown={() => clickListener(path, legal.trakingName)}
      >
        {legal.label ? legal.label.replace('defaultYear', defaultYear) : ''}
        <span className="fff-shared-footer-legal-separator">{legal.separator}</span>
      </a>
    ) : (
      <div key={legal.id} className={`legal-footer-items order-${index} legal-footer-text-only`}>
        {legal.label ? legal.label.replace('defaultYear', defaultYear) : ''}
      </div>
    );
  };

  return (
    <div className="bottom-legal-footer-container">
      <div className="bottom-legal-footer-logo-content">
        <img src={FFFLogo} alt="FabFitFun logo" />
      </div>
      <div className="bottom-legal-footer-content">
        {legals.map((legal, index) => createLegalLinkItem(legal, index))}
        {allowedStates.includes(state) && !isLoggedIn && (
          <div
            id="ot-sdk-btn"
            className="ot-sdk-show-settings legal-footer-items legal-footer-text-only"
          >
            Do Not Sell or Share my Personal Information
          </div>
        )}
      </div>
    </div>
  );
};

export { LegalFooter };
