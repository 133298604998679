import {
  createContext,
  useContext,
  useState,
  type FunctionComponent,
  type PropsWithChildren,
} from 'react';
import * as newMemberStates from '../helpers/constants/newMemberStates';
import { MembershipInfo, useMembershipInfo } from '../hooks/useMembershipInfo';

export type UniversalNavigationContextProps = {
  timerLoading: boolean;
  onTimerFetched: (timer: Timer | null) => void;
  timer: Timer | null;
  isNewMemberFlowFinished: boolean;
  isNewMember: boolean;
  membershipInfo: MembershipInfo;
  updateInternalNewMemberState: (newState: string | null) => void;
  isAccessibilityButtonVisible: boolean;
  setAccessibilityButtonVisible: (visible: boolean) => void;
};

export type Timer = {
  userId: number;
  startTime: string;
  endTime: string;
  customizeCampaignId: number | null;
  flashSaleId: number | null;
  addonCampaignId: number | null;
  lastVisitedCustomizeQuestionId: number;
  lastVisitedPage: string;
  checkedOut: boolean;
  newMemberState: string | null;
  timerSource: 'introProduct' | 'publicCustomize' | 'seasonalBox';
  timerType: 'NEW_MEMBER' | 'REACTIVATION';
};

const universalNavigationContextDefaultProps: UniversalNavigationContextProps = {
  isNewMemberFlowFinished: true,
  isNewMember: false,
  timerLoading: true,
  membershipInfo: {} as MembershipInfo,
  timer: null,
  onTimerFetched: () => {},
  updateInternalNewMemberState: () => {},
  isAccessibilityButtonVisible: true,
  setAccessibilityButtonVisible: () => {},
};

export const UniversalNavigationContext = createContext<UniversalNavigationContextProps>({
  ...universalNavigationContextDefaultProps,
});

export const UniversalNavigationContextProvider: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const [timer, setTimer] = useState<Timer | null>(null);
  const [timerLoading, setTimerLoading] = useState(true);
  const [isAccessibilityButtonVisible, setAccessibilityButtonVisible] = useState(true);
  const { membershipInfo } = useMembershipInfo();

  const { newMemberState } = timer || {};

  const isNewMember =
    !!newMemberState &&
    ![
      newMemberStates.PASSIVE_CHECKOUT,
      newMemberStates.ACTIVE_CHECKOUT,
      newMemberStates.TIMER_COMPLETED,
    ].includes(newMemberState);

  const isNewMemberFlowFinished =
    !newMemberState ||
    [
      newMemberStates.READY_FOR_SALE,
      newMemberStates.USER_ONBOARDING_1,
      newMemberStates.USER_ONBOARDING_2,
      newMemberStates.USER_ONBOARDING_3,
      newMemberStates.USER_ONBOARDING_4,
      newMemberStates.USER_COMPLETED,
      newMemberStates.PASSIVE_CHECKOUT,
      newMemberStates.ACTIVE_CHECKOUT,
      newMemberStates.TIMER_COMPLETED,
    ].includes(newMemberState);

  const onTimerFetched = (timer: Timer | null) => {
    setTimer(timer);
    setTimerLoading(false);
  };

  const updateInternalNewMemberState = (newState: string | null) => {
    if (timer) {
      setTimer({
        ...timer,
        newMemberState: newState,
      });
    }
  };

  return (
    <UniversalNavigationContext.Provider
      value={{
        timerLoading,
        onTimerFetched,
        timer,
        isNewMemberFlowFinished,
        isNewMember,
        membershipInfo,
        updateInternalNewMemberState,
        isAccessibilityButtonVisible,
        setAccessibilityButtonVisible,
      }}
    >
      {children}
    </UniversalNavigationContext.Provider>
  );
};

export const useUniversalNavigationContext = () => {
  const context = useContext(UniversalNavigationContext);
  if (!context) {
    throw new Error(
      'You must use the UniversalNavigationContextProvider in order to consume this context.',
    );
  }
  return context;
};
