import { PlanCode } from '../../interfaces';
import PaymentType from '../../interfaces/EPaymentType';

// Register API Error Codes
const ACTIVE_USER_EXISTS = 'ACTIVE_USER_EXISTS';
const INACTIVE_USER_EXISTS = 'INACTIVE_USER_EXISTS';

const PLAN_CODE = 'planCode';
const ANNUAL_PLAN_CODE: PlanCode = 'fffvipa';
const SEASONAL_PLAN_CODE: PlanCode = 'fffvip';

const CREDIT_CARD = 'CREDIT_CARD';
const PAYPAL = 'PAYPAL';
const APPLE_PAY = 'APPLE_PAY';
const VENMO = 'VENMO';

const paymentTypesDictionary = {
  CREDIT_CARD: PaymentType.CREDIT_CARD,
  PAYPAL: PaymentType.PAYPAL,
  APPLE_PAY: PaymentType.APPLE_PAY,
  VENMO: PaymentType.VENMO,
};

// Segment and tracking related constants
const ACQUISITION_COMPLETED = 'Acquisition Completed';
const GTB_PURCHASE = 'GTB Purchase'; // a box subscription purchase on /get-the-box page
const PUBLIC_SUB = 'Public Sub'; // a purchase on Public Shop which only contains 1 or more Public Sub
const INTRO_PURCHASE = 'Intro Product'; // a purchase on Public Shop which only contains 1 or more Intro Product
const PUBLIC_SUB_MEMBER_PURCHASE = 'Public Sub & Intro Product'; // a purchase on Public Shop which only contains 1 or more Public Sub and 1 or more Intro Product
const PUBLIC_SUB_NON_MEMBER_PURCHASE = 'Public Sub & Non-Member Purchase'; // a purchase on Public Shop which only contains 1 or more Public Sub and 1 or more SKU at the “Non-Member” purchase price
const NON_MEMBER_PURCHASE = 'Non-Member Purchase Intro'; // a purchase on Public Shop which only contains 1 or more SKU at the “Non-Member” purchase price

const PURCHASE_PRODUCT_TYPE = {
  gtb: GTB_PURCHASE,
  publicSub: PUBLIC_SUB,
  introProduct: INTRO_PURCHASE,
  publicSubMember: PUBLIC_SUB_MEMBER_PURCHASE,
  publicSubNonMember: PUBLIC_SUB_NON_MEMBER_PURCHASE,
  nonMember: NON_MEMBER_PURCHASE,
};

const PURCHASE_EVENT = {
  gtb: {
    event: 'purchase',
    loggerMessage: 'GTB - PURCHASE EVENT',
  },
  intro: {
    event: 'Intro Product Subscribe',
    loggerMessage: 'INTRO PRODUCT - PURCHASE EVENT',
  },
  refill: {
    event: 'Public Subscription Purchase',
    loggerMessage: 'PUBLIC SUBSCRIPTION - PURCHASE EVENT',
  },
};

// Cookie related constants
const COOKIE = {
  key: 'fff_registration_flow',
  value: 'intro-product',
};

// Tracking event names and properties
const FORM_ELEMENT_CLICKED = 'Clicked Element';
const FORM_ENTRY = 'Form Entry';

// Test ids:
const PASSWORD_TOOLTIP_CONTAINER = 'Password ToolTip';

const CADENCE_VERBOSE_STRING = {
  NINETY_DAYS: '90 days',
  GO_WITH_BOX: 'season with box',
};

const ACCOUNT_TRACK_NAMES = {
  started: 'Account Update Started',
  completed: 'Account Update Completed',
};

const ACCOUNT_SECTIONS = {
  billing: 'Billing Info',
  personal: 'Personal Info',
  shipping: 'Shipping Info',
};

const ACCOUNT_INFO = {
  payment: 'Payment',
  address: 'Address',
  name: 'Name',
  email: 'Email',
};

const CHECKOUT_STATE_FIELDS = {
  planCode: 'planCode',
  email: 'email',
  password: 'password',
  isEmailUnique: 'isEmailUnique',
  firstName: 'firstName',
  lastName: 'lastName',
  shipZip: 'shipZip',
  shipCountry: 'shipCountry',
  shipState: 'shipState',
  postalCode: 'postalCode',
  promoCode: 'promoCode',
  discount: 'discount',
  promoCodeType: 'promoCodeType',
  total: 'total',
  billingMethod: 'billingMethod',
  hCaptchaToken: 'hCaptchaToken',
  recurlyError: 'recurlyError',
  formError: 'formError',
  shipZipError: 'shipZipError',
  loading: 'loading',
  acceptedTerms: 'acceptedTerms',
  shipFirstName: 'shipFirstName',
  shipLastName: 'shipLastName',
  shipAddress1: 'shipAddress1',
  shipAddress2: 'shipAddress2',
  shipCity: 'shipCity',
  phoneNumber: 'phoneNumber',
  smsOptIn: 'smsOptIn',
};

export {
  ACCOUNT_INFO,
  ACCOUNT_SECTIONS,
  ACCOUNT_TRACK_NAMES,
  ACQUISITION_COMPLETED,
  ACTIVE_USER_EXISTS,
  ANNUAL_PLAN_CODE,
  APPLE_PAY,
  CADENCE_VERBOSE_STRING,
  CHECKOUT_STATE_FIELDS,
  COOKIE,
  CREDIT_CARD,
  FORM_ELEMENT_CLICKED,
  FORM_ENTRY,
  INACTIVE_USER_EXISTS,
  PASSWORD_TOOLTIP_CONTAINER,
  PAYPAL,
  PLAN_CODE,
  PURCHASE_EVENT,
  PURCHASE_PRODUCT_TYPE,
  SEASONAL_PLAN_CODE,
  VENMO,
  paymentTypesDictionary,
};
