import { useState } from 'react';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LinkShape } from '../../services/types';
import { LinkItem } from '../LinkItem/LinkItem';
import './AccordionFooterLink.scss';

interface Props {
  header: string;
  items: LinkShape[];
  handleOnClickMenuLink: (link: LinkShape, path: string) => void;
}

const AccordionFooterLink = ({ header, items, handleOnClickMenuLink }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => setIsOpen(!isOpen);

  return (
    <div className="accordion">
      <div
        className="accordion-header"
        onClick={toggleAccordion}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
            toggleAccordion();
          }
        }}
        role="button"
        tabIndex={0}
      >
        <span>{header}</span>
        <FontAwesomeIcon
          icon={faChevronRight}
          className={`accordion-icon ${isOpen ? 'open' : ''}`}
        />
      </div>

      {isOpen && (
        <div className="accordion-content">
          {items.map((item) => (
            <LinkItem
              key={item.id}
              link={item}
              className="accordion-item"
              handleOnClick={handleOnClickMenuLink}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export { AccordionFooterLink };
