import { bool, shape, string } from 'prop-types';
import { trackElement } from '@fff-web/fff-analytics';
import { isAddressVisible } from './helpers';

const AddressDetails = ({
  shippingAddress = {
    userName: '',
    address1: '',
    city: '',
    state: '',
    postalCode: '',
  },
  hasFailed = false,
  boxStatusId,
  wizmoMode,
}) => (
  <div className="challenge-address-container">
    {isAddressVisible(boxStatusId) && !hasFailed && (
      <>
        <span className="address-title">SHIPPING ADDRESS</span>
        {shippingAddress && (
          <>
            <p className="address-details">{shippingAddress.userName}</p>
            <p className="address-piece">{shippingAddress.address1}</p>
            {shippingAddress.address2 && (
              <p className="address-piece">{shippingAddress.address2}</p>
            )}
            <p className="address-piece">
              {`${shippingAddress.city}, ${shippingAddress.state} ${shippingAddress.postalCode}`}
            </p>
          </>
        )}
        {!wizmoMode && (
          <a
            href="/my-account"
            onClick={() => trackElement({ elementName: 'EDIT ADDRESS - BOX STATUS' })}
            className="highlighted"
          >
            Edit
          </a>
        )}
      </>
    )}
  </div>
);

AddressDetails.propTypes = {
  shippingAddress: shape({
    userName: string,
    address1: string,
    city: string,
    state: string,
    postalCode: string,
  }),
  hasFailed: bool,
  boxStatusId: string,
  wizmoMode: bool,
};

export default AddressDetails;
