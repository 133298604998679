import type { FunctionComponent } from 'react';
import { keyframes, styled } from 'styled-components';
import fffLogo from './FFFLogo.svg';

export const iconTestId = 'loading-spinner-icon';
export const backdropTestId = 'loading-backdrop';

const LoadingBackdrop = styled.div`
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 72;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100%;
  z-index: 1000;
`;

const loading = keyframes`
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.9);
  }
`;

const FFFLoadingIcon = styled.img`
  animation-name: ${loading};
  animation-duration: 0.5s;
  animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
  animation-delay: 0s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-fill-mode: none;
  width: 100px;
  height: 100px;
`;

export const LoadingSpinner: FunctionComponent<{ isFullPage?: boolean }> = ({
  isFullPage = true,
}) => {
  if (!isFullPage) {
    return <FFFLoadingIcon data-testid={iconTestId} src={fffLogo} alt="page loading" />;
  }

  return (
    <LoadingBackdrop data-testid={backdropTestId}>
      <FFFLoadingIcon
        data-testid={iconTestId}
        data-e2e={iconTestId}
        src={fffLogo}
        alt="page loading"
      />
    </LoadingBackdrop>
  );
};

export default LoadingSpinner;
