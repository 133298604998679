import _ from 'lodash';
import { SALE_TYPES } from './campaign';
import { convertToCents, convertToDollars, formatCurrencyInCents } from './currency';
import { VARIANT_TYPES } from './types';

const LINE_ITEM_SKUS = {
  shipping: 'shipping_charge',
  service: 'service_charge',
};

const isFlashSale = (campaignType) =>
  campaignType === SALE_TYPES.SEASON || campaignType === SALE_TYPES.EDIT;

const getSaleCredit = (initialCredits, campaignType) => {
  const campaign = campaignType.toLowerCase();
  return _.get(initialCredits, `${campaign}.credit`, 0);
};

export const getCartSubtotal = (cartVariants) => {
  let subtotal = 0;
  _.forEach(cartVariants, (variant) => {
    const { price, salePrice, quantity, variantType } = variant;
    const displayedPrice =
      variantType === VARIANT_TYPES.prospectSingle ? price : salePrice || price;
    const priceInCents = convertToCents(displayedPrice);
    subtotal += priceInCents * quantity;
  });
  return subtotal;
};

/**
 * Applies a credit to a total
 * @param {number} total - The total to apply the credit to
 * @param {number} credit - The credit to apply
 * @returns {number} The applied credit
 */
function applyCredit(total, credit) {
  const appliedCredit = Math.min(total, credit);

  return appliedCredit;
}

/**
 * Gets the credits applied to a purchase
 * @param {number} total - The total to apply the credit to
 * @param {object} credits - The credits to apply
 * @param {number} credits.saleCredit - The sale credit to apply
 * @param {number} credits.shoppingCredit - The shopping credit to apply
 * @param {number} credits.purchaseCredit - The purchase credit to apply
 * @returns {object} The applied credits
 */
function getCreditsAppliedToPurchase(total, { saleCredit, shoppingCredit, purchaseCredit }) {
  let remainingAmmount = total;

  const appliedSaleCredit = applyCredit(remainingAmmount, saleCredit);
  const hasAppliedSaleCredit = appliedSaleCredit > 0;
  remainingAmmount -= appliedSaleCredit;

  const appliedShoppingCredit = applyCredit(remainingAmmount, shoppingCredit);
  const hasAppliedShoppingCredit = appliedShoppingCredit > 0;
  remainingAmmount -= appliedShoppingCredit;

  const appliedPurchaseCredit = applyCredit(remainingAmmount, purchaseCredit);
  const hasAppliedPurchaseCredit = appliedPurchaseCredit > 0;

  return {
    appliedSaleCredit,
    hasAppliedSaleCredit,
    appliedShoppingCredit,
    hasAppliedShoppingCredit,
    appliedPurchaseCredit,
    hasAppliedPurchaseCredit,
  };
}

export const getAllCreditValues = (
  subtotal,
  initialCredits,
  campaignType,
  shoppingCreditFF,
  shippingTotal,
) => {
  const { purchaseCredit = 0, shoppingCredit = 0 } = initialCredits;
  const shoppingCreditWithFF = shoppingCreditFF ? shoppingCredit : 0;
  const saleCredit = isFlashSale(campaignType) ? getSaleCredit(initialCredits, campaignType) : 0;

  const hasPurchaseCredit = purchaseCredit > 0;
  const hasSaleCredit = saleCredit > 0;
  const hasShoppingCredit = shoppingCreditWithFF > 0;

  const subtotalPlusShipping = subtotal + shippingTotal;

  const remainingCredit = Math.max(
    0,
    saleCredit + purchaseCredit + shoppingCreditWithFF - subtotalPlusShipping,
  );

  const {
    appliedSaleCredit,
    hasAppliedSaleCredit,
    appliedShoppingCredit,
    hasAppliedShoppingCredit,
    appliedPurchaseCredit,
    hasAppliedPurchaseCredit,
  } = getCreditsAppliedToPurchase(subtotalPlusShipping, {
    saleCredit,
    shoppingCredit: shoppingCreditWithFF,
    purchaseCredit,
  });

  return {
    purchaseCredit: formatCurrencyInCents(purchaseCredit),
    hasPurchaseCredit,
    saleCredit: formatCurrencyInCents(saleCredit),
    hasSaleCredit,
    remainingCredit: formatCurrencyInCents(remainingCredit),
    appliedPurchaseCredit: formatCurrencyInCents(appliedPurchaseCredit),
    hasAppliedPurchaseCredit,
    appliedSaleCredit: formatCurrencyInCents(appliedSaleCredit),
    hasAppliedSaleCredit,
    hasShoppingCredit,
    shoppingCredit: formatCurrencyInCents(shoppingCredit),
    appliedShoppingCredit: formatCurrencyInCents(appliedShoppingCredit),
    hasAppliedShoppingCredit,
  };
};

export const getCartTotal = (
  subtotal,
  initialCredits,
  campaignType,
  tax,
  shippingTotal,
  shoppingCreditFF,
) => {
  const { purchaseCredit = 0, shoppingCredit = 0 } = initialCredits;

  const saleCredit = isFlashSale(campaignType) ? getSaleCredit(initialCredits, campaignType) : 0;

  const shoppingCreditWithFF = shoppingCreditFF ? shoppingCredit : 0;

  const totalAfterCreditApplied = Math.max(
    0,
    subtotal + shippingTotal - saleCredit - purchaseCredit - shoppingCreditWithFF,
  );

  const total = totalAfterCreditApplied + convertToCents(tax);
  return total;
};

export const getShipping = (cartVariants, cartDetails, subtotal, publicShopFreeShipping) => {
  const { shippingFee, amountToFreeShipping, additionalShippingFee, isShippingWithBox } =
    cartDetails;
  const cartShippingExempt = cartVariants.every((variant) => variant.shippingExempt);
  // for some reason, amount to free shipping comes from API as dollar value instead of cents
  const amountToFreeShippingCents = convertToCents(amountToFreeShipping);
  const remainingSpendToFreeShipping = amountToFreeShippingCents - subtotal;
  const hasFreeShipping =
    remainingSpendToFreeShipping <= 0 ||
    cartShippingExempt ||
    isShippingWithBox ||
    publicShopFreeShipping;
  const shippingAmount = hasFreeShipping ? 0 : Number(shippingFee);
  const additionalShippingAmount = Number(additionalShippingFee);

  return {
    shippingAmount,
    additionalShippingAmount,
    remainingSpendToFreeShipping: hasFreeShipping ? 0 : remainingSpendToFreeShipping,
    showShipping: !cartShippingExempt,
  };
};

// returns true if there are OOS products or they are no longer available
export const getUnavailableProducts = (cartVariants) =>
  !!_.find(cartVariants, (variant) => variant.soldOut || variant.quantity > variant.inventoryLimit);

export const getTaxLineItems = (cartVariants, shippingAmount, additionalShippingAmount) => {
  const newLineItems = _.reduce(
    cartVariants,
    (acc, item) => {
      const { shippingExempt, taxExempt, sku, name: description, quantity, salePrice } = item;
      if (!shippingExempt && !taxExempt) {
        acc.push({
          sku,
          description,
          quantity,
          unitPrice: Number(salePrice),
        });
      }
      return acc;
    },
    [],
  );
  if (shippingAmount)
    newLineItems.push({
      sku: LINE_ITEM_SKUS.shipping,
      description: 'Shipping Fee',
      quantity: 1,
      unitPrice: convertToDollars(shippingAmount),
    });
  if (additionalShippingAmount)
    newLineItems.push({
      sku: LINE_ITEM_SKUS.service,
      description: 'Additional Shipping Fee',
      quantity: 1,
      unitPrice: convertToDollars(additionalShippingAmount),
    });
  return newLineItems;
};
