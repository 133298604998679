import cx from 'classnames';
import { bool, number, oneOfType, shape, string } from 'prop-types';
import { getNodeMessage } from '../../helpers/timeline';

const Node = ({
  isBoxNode = false,
  isMobile = true,
  data: { message, date, status, link } = {},
  trackingUrl = '',
  bodyDetail = '',
}) => {
  const filledShippedNode = (status === 'active' || status === 'past') && message === 'Shipped';
  const showBodyDetail = status === 'active' && bodyDetail;

  return isBoxNode ? (
    <a
      className={cx(
        {
          'challenge-timeline__node': isMobile,
          'challenge-timeline__node-desktop': !isMobile,
          'challenge-timeline__node-cursor': filledShippedNode,
        },
        status,
      )}
      href={filledShippedNode ? trackingUrl : null}
      target="_blank"
      rel="noreferrer"
    >
      <div className={cx('timeline__node__point', status)} />
      <div
        className={
          isMobile ? 'challenge-timeline__node__label' : 'challenge-timeline__node__label-desktop'
        }
      >
        {message}
        {showBodyDetail && (
          <div
            className="timeline__node__current-step-message"
            dangerouslySetInnerHTML={{ __html: bodyDetail }}
          />
        )}
      </div>
    </a>
  ) : (
    <div className="timeline__node">
      <div className={cx('timeline__node__point', status)} />
      {link && (
        <a className="timeline__node__label" href={link}>
          {message}
        </a>
      )}
      {!link && <div className="timeline__node__label">{getNodeMessage(message, date)}</div>}
    </div>
  );
};

Node.propTypes = {
  data: shape({
    id: oneOfType([string, number]),
    message: string,
    order: oneOfType([string, number]),
    date: string,
    group: string,
    link: string,
  }),
  isBoxNode: bool,
  isMobile: bool,
  trackingUrl: string,
  showBodyDetail: string,
  bodyDetail: string,
};

export default Node;
