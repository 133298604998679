import { useEffect, useRef, useState, type FunctionComponent } from 'react';
import _ from 'lodash';
import { useDataContext } from '../../../context/DataContext';
import { useInternalContext } from '../../../context/InternalContext';
import {
  CAMPAIGN_STATUS,
  campaignHasEnded,
  getIsOnOwnPage,
  SALE_TYPES,
} from '../../../services/utils/campaign';
import { WINDOW_SIZE } from '../../../services/utils/device';
import { SUBSCRIPTION_STATE } from '../../../services/utils/types';
import useWindowSize from '../../../shared/hooks/useWindowSize';
import Cart from '../Cart/Cart';
import ClosedSale from '../ClosedSale/ClosedSale';
import EmptyState from '../EmptyState/EmptyState';
import FlashSales from '../FlashSales/FlashSales';
import Header from '../Header/Header';
import LoadingCart from '../LoadingCart/LoadingCart';
import Tabs from '../Tabs/Tabs';
import * as styles from './CartContent.module.css';

const TABS = {
  SHOP_TAB: 1,
  SALE_TAB: 0,
};

type CartContentProps = {
  activeCampaign: {
    type: string;
    campaignStatus: string;
    userAccess: boolean;
  };
  shopCampaignData: {
    userAccess: boolean;
  };
  isLoading: boolean;
  saleCartDataState: {
    itemsCount: number;
  };
  setSaleCartDataState: (cartData: { itemsCount: number }) => void;
  shopCartDataState: {
    itemsCount: number;
  };
  setShopCartDataState: (cartData: { itemsCount: number }) => void;
  closeCart: () => void;
  receiveUpdateCart: boolean;
  receiveCartProducts: boolean;
  freeTierShopAccessFF: boolean;
  isPublicShopping: boolean;
  itemsCount: number;
  seasonalPlanPrice: number;
  selfCheckoutFF: boolean;
  flashSaleDiscountCouponsFF: boolean;
};

const CartContent: FunctionComponent<CartContentProps> = ({
  activeCampaign,
  shopCampaignData,
  isLoading,
  saleCartDataState,
  setSaleCartDataState,
  shopCartDataState,
  setShopCartDataState,
  closeCart,
  receiveUpdateCart,
  receiveCartProducts,
  freeTierShopAccessFF,
  isPublicShopping,
  itemsCount,
  seasonalPlanPrice,
  selfCheckoutFF,
  flashSaleDiscountCouponsFF,
}) => {
  const { hostname, isShopPage, page } = useInternalContext();
  const { userData, isNewMember, isNewMemberFlow } = useDataContext();
  const itemsContainerRef = useRef<HTMLDivElement>(null);
  const cartContainerRef = useRef<HTMLDivElement>(null);
  const windowSize = useWindowSize();

  const activeCampaignType = _.get(activeCampaign, 'type', SALE_TYPES.SEASON);
  const isOwnPage = getIsOnOwnPage(page, activeCampaignType);
  const isExpired = userData?.subscriptionState === SUBSCRIPTION_STATE.expired;
  const redirectToReactivate = () => {
    global.window.location.href = `https://${hostname}/reactivate-my-membership`;
  };

  const [activeTab, setActiveTab] = useState(isShopPage ? TABS.SHOP_TAB : TABS.SALE_TAB);

  // body scroll lock
  useEffect(() => {
    const elemRef = itemsContainerRef.current || cartContainerRef.current;
    if (windowSize === WINDOW_SIZE.mobile && elemRef) {
      document.body.classList.add(styles.scrollContain);
    }
    return () => document.body.classList.remove(styles.scrollContain);
  }, [windowSize, activeTab]);

  // pre select tabs
  useEffect(() => {
    if (isShopPage || !activeCampaign) {
      setActiveTab(TABS.SHOP_TAB);
    } else {
      setActiveTab(TABS.SALE_TAB);
    }
  }, [isShopPage, activeCampaign]);

  const renderSaleContent = () => {
    if (isPublicShopping) {
      return (
        <Cart
          receiveUpdateCart={isOwnPage && receiveUpdateCart}
          cartData={shopCartDataState}
          campaignData={activeCampaign}
          setCartData={setShopCartDataState}
          closeCart={closeCart}
          itemsContainerRef={itemsContainerRef}
          tab="Flash Sale"
          isPublicShopping
          seasonalPlanPrice={seasonalPlanPrice}
          selfCheckoutFF={selfCheckoutFF}
          flashSaleDiscountCouponsFF={flashSaleDiscountCouponsFF}
        />
      );
    }
    if (activeCampaign && !campaignHasEnded(activeCampaign.campaignStatus)) {
      if (activeCampaign.userAccess) {
        return (
          <Cart
            receiveUpdateCart={isOwnPage && receiveUpdateCart}
            cartData={saleCartDataState}
            campaignData={activeCampaign}
            setCartData={setSaleCartDataState}
            closeCart={closeCart}
            itemsContainerRef={itemsContainerRef}
            tab="Flash Sale"
            isPublicShopping={isPublicShopping}
            seasonalPlanPrice={seasonalPlanPrice}
            selfCheckoutFF={selfCheckoutFF}
            flashSaleDiscountCouponsFF={flashSaleDiscountCouponsFF}
          />
        );
      }
      return (
        <ClosedSale
          campaignStatus={_.get(activeCampaign, 'campaignStatus', CAMPAIGN_STATUS.ended)}
          campaignType={activeCampaignType}
          isOnOwnPage={isOwnPage}
          closeCart={closeCart}
        />
      );
    }
    return <FlashSales isOnOwnPage={getIsOnOwnPage(page, 'SEASON')} closeCart={closeCart} />;
  };

  const renderSecondTab = () => {
    if (!shopCampaignData.userAccess || (isExpired && !freeTierShopAccessFF)) {
      return (
        <EmptyState
          icon="fal fa-shopping-bag"
          title="Want to browse The Shop?"
          description="Access to The Shop is a member perk."
          cta="Reactivate Now"
          action={redirectToReactivate}
        />
      );
    }
    return (
      <Cart
        receiveUpdateCart={isShopPage && receiveUpdateCart}
        receiveCartProducts={isShopPage && receiveCartProducts}
        cartData={shopCartDataState}
        campaignData={shopCampaignData}
        setCartData={setShopCartDataState}
        closeCart={closeCart}
        itemsContainerRef={itemsContainerRef}
        tab="The Shop"
        isPublicShopping={isPublicShopping}
        seasonalPlanPrice={seasonalPlanPrice}
        selfCheckoutFF={selfCheckoutFF}
        flashSaleDiscountCouponsFF={flashSaleDiscountCouponsFF}
      />
    );
  };

  const showSecondaryTab = !isNewMember || !isNewMemberFlow;

  return (
    <div className={styles.cart} ref={cartContainerRef}>
      <div className={styles.cartContentWrapper}>
        {showSecondaryTab && (
          <Header closeCart={closeCart} isPublicShopping={isPublicShopping} itemsQty={itemsCount} />
        )}
        {isLoading ? (
          <LoadingCart windowSize={windowSize} />
        ) : (
          <>
            {!isPublicShopping && (
              <Tabs
                data-testid="tabs"
                activeCampaign={activeCampaign}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                saleItemsCount={_.get(saleCartDataState, 'itemsCount')}
                shopItemsCount={_.get(shopCartDataState, 'itemsCount')}
                isNewMember={isNewMember}
                showSecondaryTab={showSecondaryTab}
              />
            )}
            {activeTab === 0 || isPublicShopping ? renderSaleContent() : renderSecondTab()}
          </>
        )}
      </div>
    </div>
  );
};

export default CartContent;
