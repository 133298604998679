// extracted by mini-css-extract-plugin
export var additionalChoiceContainer = "CartItem_additionalChoiceContainer__p4ZD1";
export var cadence = "CartItem_cadence__MCXuy";
export var container = "CartItem_container__XiqzL";
export var content = "CartItem_content__XHbSM";
export var imageLink = "CartItem_imageLink__Km-Uq";
export var nakedButton = "CartItem_nakedButton__kHYM4";
export var name = "CartItem_name__najLc";
export var price = "CartItem_price__zPDWP";
export var right = "CartItem_right__kYzUA";
export var shared = "\"../../../shared/shared.module.css\"";
export var sm = "576px";
export var strikethroughPrice = "CartItem_strikethroughPrice__OYAH5";
export var variant = "CartItem_variant__rYLBo";
export var warning = "CartItem_warning__X43Em";