import { Fragment, useEffect, useState } from 'react';
import { datadogLogs } from '@datadog/browser-logs';
import { BlocksRenderer } from '@strapi/blocks-react-renderer';
import { useViewSize } from '@fff-web/fff-utilities';
import { getBannerByName } from '../../api/banners';
import fallbacks from '../../utils/banners/fallbacks';
import { BannerDataType } from '../../utils/banners/types';
import * as styles from './Banner.module.css';

interface BannerProps {
  name: string;
  themeColor?: 'dark' | 'light' | 'darkTransparent' | 'lightTransparent';
  onClickCTA?: () => void;
  customSubtitle?: string;
  className?: string;
}

const bannerError = {
  title: 'Unable to display banner.',
  imageDesktop: '',
  imageMobile: '',
};

const getTitle = (title: string | Array<string>) => {
  if (Array.isArray(title)) {
    return (
      <h1>
        {title.map((line, index) => (
          <Fragment key={index}>
            {line}
            {index < title.length - 1 && <br />}
          </Fragment>
        ))}
      </h1>
    );
  } else if (typeof title === 'string' && title.replace(/\s/g, '').length) {
    return <h1>{title}</h1>;
  }
  return null;
};

const Banner = ({
  name,
  themeColor = 'light',
  onClickCTA,
  customSubtitle,
  className = '',
}: BannerProps) => {
  const [loading, setLoading] = useState(true);
  const [banner, setBanner] = useState<BannerDataType>(fallbacks[name] || bannerError);
  const { isDesktop } = useViewSize();

  const THEME_MAP = {
    dark: styles.themeDark,
    light: styles.themeLight,
    darkTransparent: styles.themeDarkTransparent,
    lightTransparent: styles.themeLightTransparent,
  };

  useEffect(() => {
    const fetchBanner = async () => {
      try {
        const banner = await getBannerByName(name);
        if (banner.title && banner.imageDesktop && banner.imageMobile) {
          setBanner(banner);
        }
      } catch (error: unknown) {
        datadogLogs.logger.error(`Error displaying banner ${name} from CMS`, {}, error as Error);
      }
      setLoading(false);
    };
    fetchBanner();
  }, []);

  if (loading || !banner) return null;

  const {
    title,
    subtitle,
    cta,
    secondCta,
    imageDesktop,
    imageMobile,
    ctaUrl,
    secondCtaUrl,
    ctaBackgroundColor,
    ctaFontColor,
    secondCtaBackgroundColor,
    secondCtaFontColor,
    fontColor,
  } = banner;

  const backgroundImage = isDesktop ? imageDesktop : imageMobile;
  const themeClass = THEME_MAP[themeColor];
  const componentClassName = styles[className];

  const handleClickCTA = () => {
    if (ctaUrl) {
      window.location.href = ctaUrl;
    } else if (onClickCTA) {
      onClickCTA();
    }
  };

  const handleClickSecondCTA = () => {
    if (secondCtaUrl) {
      window.location.href = secondCtaUrl;
    }
  };

  return (
    <div
      className={`${styles.banner} ${themeClass} ${componentClassName}`}
      style={{ backgroundImage: `url(${backgroundImage})` }}
      data-testid="banner"
      data-e2e="banner"
    >
      <div className={styles.container} style={fontColor ? { color: fontColor } : undefined}>
        {getTitle(title)}
        {customSubtitle && <p data-testid="custom-subtitle">{customSubtitle}</p>}
        {subtitle && <BlocksRenderer content={subtitle} />}
        <div className="buttonContainer">
          {secondCta && (
            <button
              className={styles.secondaryButton}
              onClick={handleClickSecondCTA}
              style={
                secondCtaFontColor && secondCtaBackgroundColor
                  ? { color: secondCtaFontColor, backgroundColor: secondCtaBackgroundColor }
                  : undefined
              }
            >
              {secondCta}
            </button>
          )}
          {cta && (
            <button
              onClick={handleClickCTA}
              style={
                ctaFontColor && ctaBackgroundColor
                  ? { color: ctaFontColor, backgroundColor: ctaBackgroundColor }
                  : undefined
              }
            >
              {cta}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Banner;
