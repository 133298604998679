import { SocialMediaIcon } from '../../services/types';
import FacebookIcon from '../../images/fff-facebook.svg';
import InstagramIcon from '../../images/fff-instagram.svg';
import PinterestIcon from '../../images/fff-pinterest.svg';
import TiktokIcon from '../../images/fff-tiktok.svg';
import TwitterIcon from '../../images/fff-twitter.svg';
import YoutubeIcon from '../../images/fff-youtube.svg';
import './SocialMediaFooter.scss';

const iconImages: Record<string, string> = {
  InstagramIcon,
  FacebookIcon,
  PinterestIcon,
  TwitterIcon,
  YoutubeIcon,
  TiktokIcon,
};

interface SMFooterProps {
  icons: SocialMediaIcon[];
}

const SocialMediaFooter = ({ icons }: SMFooterProps) => {
  const mappedIcons = icons?.map(({ id, route, img, icon, label }) => (
    <a key={id} href={route} data-testid={`social-media-link-${label}`}>
      {icon || (
        <img
          src={iconImages[img]}
          className="fff-shared-footer-sm-icon"
          alt={`social-icon-${label}`}
        />
      )}
    </a>
  ));

  return <div className="fff-shared-sm-container">{mappedIcons}</div>;
};

export { SocialMediaFooter };
