import { FunctionComponent, useEffect } from 'react';
import { createPortal } from 'react-dom';

import { useUniversalNavigationContext } from '../../../context/UniversalNavigationContext';
import type { NewMobileBannerProps } from './types';
import fffAppLogoRounded from './fff-app-logo-rounded.png';
import mobileScreenshots from './mobile-screenshots.png';
import * as styles from './NewMobileBanner.module.css';

const NewMobileBanner: FunctionComponent<NewMobileBannerProps> = ({
  onBannerClick,
  onBannerClose,
}) => {
  const { setAccessibilityButtonVisible } = useUniversalNavigationContext();

  useEffect(() => {
    setAccessibilityButtonVisible(false);
  }, [setAccessibilityButtonVisible]);

  useEffect(() => {
    document.body.classList.add('scrollContain');
    return () => {
      document.body.classList.remove('scrollContain');
    };
  }, []);

  const handleBannerClose = () => {
    document.body.classList.remove('scrollContain');
    setAccessibilityButtonVisible(true);
    onBannerClose();
  };

  return createPortal(
    <div className={styles.container} id="mobile-web-banner">
      <div className={styles.bannerContainer}>
        <img src={fffAppLogoRounded} alt="app-logo-rounded" className={styles.logoImage} />
        <div className={styles.textContainer}>
          <div className={styles.title}>Experience more in the app</div>
          <div className={styles.description}>
            Enjoy advanced filtering, sneak peeks into sales, seamless shopping and more from
            FabFitFun.
          </div>
        </div>
        <button
          type="button"
          onClick={onBannerClick}
          className={styles.openButton}
          data-testid="open-link-mobile-banner-button"
        >
          OPEN FABFITFUN
        </button>
        <button
          onClick={handleBannerClose}
          type="button"
          className={styles.closeNewMobileBannerText}
          data-testid="close-new-mobile-banner-button"
        >
          Continue to mobile site
        </button>
      </div>
      <img src={mobileScreenshots} alt="mobile-screenshots" className={styles.mobileScreenshots} />
    </div>,
    document.body,
  );
};

export default NewMobileBanner;
