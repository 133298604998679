import { CreditInfo, Order } from '../services/types';

export const formatOrders = (orders: Order[], amount: number = 1): string => {
  return orders
    .slice(0, amount)
    .map((order, index) => {
      const shipments = order.shipments
        .map((shipment, shipmentIndex) => {
          if (shipment.shipmentDetails) {
            return (
              `'orderShipmentId${shipmentIndex + 1}': ${shipment.shipmentDetails.orderShipmentId}, ` +
              `'trackingNumber${shipmentIndex + 1}': '${shipment.shipmentDetails.trackingNumber}', ` +
              `'status${shipmentIndex + 1}': '${shipment.shipmentDetails.status}', ` +
              `'carrier${shipmentIndex + 1}': '${shipment.shipmentDetails.carrier}'`
            );
          }
          return null;
        })
        .filter(Boolean)
        .join(', ');

      return `Order #${index + 1} orderId: ${order.orderId}, billingDate: '${order.billingDate || null}'${shipments ? ', ' + shipments : ''}`;
    })
    .join('; ');
};

export const formatCredits = (credits: CreditInfo[]): string => {
  return credits
    .map(
      (credit, index) => `Credit #${index + 1}: '${credit.creditName}', amount: ${credit.amount}`,
    )
    .join('; ');
};
