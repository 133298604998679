import { getEnv } from '../environment';
import { getCountryIsoCode } from './addresses';
import request from './request';

const env = getEnv();
const { REACT_APP_USER_URL: USER_API, REACT_APP_ACE_API_URL: ACE_API, name: ENV } = env;

export const createSessionId = async (countryIsoCode: string) => {
  const response = await request({
    path: `${USER_API}/public/create`,
    method: 'POST',
    body: JSON.stringify({
      countryIsoCode,
    }),
  });

  return response;
};

export const addSingleBoxToCart = async () => {
  const { countryIsoCode } = await getCountryIsoCode();
  await createSessionId(countryIsoCode ?? 'US');

  const CAMPAIGN_TYPE = 'SHOP';
  const ITEM_TYPE = 'PROSPECT_SINGLE';
  const OPERATION = 'ADD';

  const SINGLE_BOX_VARIANT_ID = {
    dev: 10004791,
    prod: 122276,
  };

  const variantId = SINGLE_BOX_VARIANT_ID[ENV as keyof typeof SINGLE_BOX_VARIANT_ID];

  const url = `${ACE_API}/public/api/1/carts/variants/${variantId}?campaignType=${CAMPAIGN_TYPE}&itemType=${ITEM_TYPE}&operation=${OPERATION}`;

  return request({
    path: url,
    method: 'POST',
  });
};

export default addSingleBoxToCart;
