import { datadogLogs } from '@datadog/browser-logs';
import { FFFStorage, ScriptsExists } from '@fff-web/fff-utilities';
import type { IUser } from '@fff-web/fff-utilities';
import { fetchUserMetadata } from '../services';
import { UserMetadataType } from '../services/types';
import { adaScript, adaStyle } from './chatSnippets';
import { getHomepageHost } from './getEnv';
import isBrowser from './isBrowser';
import { formatCredits, formatOrders } from './metaData';


const adaScriptId = '__ada';

const adaScriptSrc = 'https://static.ada.support/embed2.js';

const adaAIHandle = 'fff-gr';

const pagesWithAIAdaChat = [
  '/cancel-subscription',
  '/reactivate-my-membership',
  '/upgrade-confirmation',
  '/homepage',
  '/my-subscriptions',
  '/customize',
  '/my-account',
  '/my-account/',
  '/shop',
  '/',
];
type ChatType = 'ada';

/**
 * Toggles the visibility of a chat element
 * @param {ChatType} chatType The type of chat to toggle ('ada')
 * @param {boolean} show Whether to show or hide the chat
 * @returns {void}
 */
const toggleChatVisibility = (chatType: ChatType, show = false) => {
  const selectors = {
    ada: { type: 'id', value: 'ada-entry' },
  };

  const selector = selectors[chatType];
  const element =
    selector.type === 'id'
      ? document.getElementById(selector.value)
      : (document.getElementsByClassName(selector.value)[0] as HTMLElement);

  if (!element) return;

  element.style.display = show ? 'initial' : 'none';
};

/**
 * Checks if a chat widget is loaded in the page
 * @param {ChatType} chatType The type of chat to check ('ada')
 * @returns {boolean} If the chat widget is loaded
 */
const isChatLoaded = (chatType: ChatType): boolean => {
  const config = {
    ada: {
      elementCheck: () => document.getElementById('ada-entry'),
      scriptId: adaScriptId,
    },
  };

  const { elementCheck, scriptId } = config[chatType];
  const hasElement = Boolean(elementCheck());
  const hasScript = ScriptsExists.scriptExistsById(scriptId);

  return hasElement && hasScript;
};

// Replace the existing functions with exported versions that use isChatLoaded
export const getIsAdaChatLoaded = () => isChatLoaded('ada');

/**
 * Removes all the existing widgets in the page.
 * @returns {void}
 */
export const removeAllWidgets = () => {
  removeScriptIfPresent(adaScriptId);
};

export const getUserMetadata = async (): Promise<UserMetadataType | null> => {
  const userMetadataContentKey = 'user-metadata';
  const cacheDurationInMs = 15 * 60 * 1000;
  const userMetadataCache = FFFStorage.getFromStorage({ key: userMetadataContentKey });

  if (userMetadataCache) {
    return userMetadataCache;
  }

  try {
    const userMetadataResponse = await fetchUserMetadata();

    FFFStorage.setToStorage({
      key: userMetadataContentKey,
      value: userMetadataResponse,
      durationInMs: cacheDurationInMs,
    });

    return userMetadataResponse;
  } catch (e) {
    datadogLogs.logger.error('Failed to fetch User MetaData', e as Error);
    FFFStorage.removeFromStorage({ key: userMetadataContentKey });

    return null;
  }
};

const processMetaData = (metaData: UserMetadataType) => {
  return {
    phoneNumber: metaData.userAddressInfo.phoneNumber,
    addressLine1: metaData.userAddressInfo.addressLine1,
    seasonsActive: metaData.seasonsActive,
    nextBillDate: metaData.nextBillDate,
    creditCardOutdated: metaData.creditCardOutdated,
    orders: formatOrders(metaData.orders, 3),
    creditsInfo: formatCredits(metaData.creditsInfo),
  };
};

/**
 * Loads the proper settings script and the Ada chatbot script to the page.
 */
const loadAdaChat = async (userInfo: IUser) => {
  const isAdaChatbotLoaded = getIsAdaChatLoaded();
  const metaData = await fetchUserMetadata();
  const userMetadata = processMetaData(metaData);

  if (isAdaChatbotLoaded) {
    toggleChatVisibility('ada', true);
    return;
  }
  document.head.insertAdjacentHTML('beforeend', adaStyle);
  window.liveChatEnded = false;

  const adaScriptEl = document.createElement('script');
  adaScriptEl.innerHTML = adaScript;
  adaScriptEl.type = 'text/javascript';
  document.body.appendChild(adaScriptEl);
  if (userInfo?.email) {
    window.adaSettings = {
      ...window.adaSettings,
      metaFields: {
        ...userInfo,
        ...userMetadata,
      },
    };
  }

  /**
   * This script must be loaded after the 'window.adaSettings'
   * (added in 'adaScriptEl') is being declared.
   */
  if (!ScriptsExists.scriptExistsById(adaScriptId)) {
    const blockScript = document.createElement('script');
    blockScript.id = adaScriptId;
    blockScript.setAttribute('data-handle', adaAIHandle);
    blockScript.src = adaScriptSrc;
    blockScript.type = 'text/javascript';
    document.body.appendChild(blockScript);
  }
};

/**
 * Checks if a script is present in the page and if it's present
 * it's being removed from the page.
 * @param {string} scriptId The name of the script to remove.
 * @returns {void}
 */
const removeScriptIfPresent = (scriptId: string) => {
  const isAdaChatbotLoaded = getIsAdaChatLoaded();

  if (scriptId === adaScriptId) {
    if (isAdaChatbotLoaded) {
      toggleChatVisibility('ada', false);
      return;
    }
  }

  const element = document.getElementById(scriptId);

  if (element) {
    console.log(`removing scriptId ${scriptId}`);
    element.parentNode.removeChild(element);
  }
};

/**
 * Loads the chat and its settings according to the configurations provided.
 */
export const loadChat = (userInfo: IUser) => {
  if (!isBrowser()) {
    return false;
  }
  let currentPath = window.location && window.location.pathname ? window.location.pathname : '';

  if (currentPath !== '/') {
    // This condition is to render the chat on GET THE BOX page
    if (currentPath.endsWith('/')) {
      currentPath = currentPath.substr(0, currentPath.length - 1);
    }
  }

  if (currentPath.length === 0 && window.location.host === getHomepageHost()) {
    currentPath = '/homepage';
  }

  const isSupportPage = window.location.host.includes('support');
  const isShipmentTrackingPage = window.location.pathname.includes('track-shipment');

  const useAda =
    pagesWithAIAdaChat.includes(currentPath) || isSupportPage || isShipmentTrackingPage;

  if (useAda) {
    console.log('Ada chat loaded');
    removeAllWidgets();
    return loadAdaChat(userInfo);
  }

  removeAllWidgets();
  console.log('No chat loaded');
  return false;
};
