import get from 'lodash/get';
import { cookie } from '../utils/cookie';
import { ACE_API_HOSTS, HOSTNAMES } from '../utils/env';

const request = async (endpoint, method, env, headers) => {
  const isLocal = env === 'local';
  const host = get(ACE_API_HOSTS, env);
  const origin = get(HOSTNAMES, env);
  const defaultHeaders = {
    'FFF-Auth': 'V1.1',
    Origin: origin,
    ...(isLocal && { Authorization: `Bearer ${cookie.get('Jwt')}` }),
  };
  const response = await fetch(host + endpoint, {
    method,
    mode: 'cors',
    headers: {
      ...defaultHeaders,
      ...headers,
    },
    ...(!isLocal && { credentials: 'include' }),
  });
  if (!response.ok) {
    throw response.text();
  }
  return response.json();
};

export default request;
