import { SALE_TYPES } from '../services/utils/campaign';
import { isPreviewingCampaign } from '../services/utils/page';

export const TABS = {
  SEASON: {
    id: 'add-ons',
    name: 'Add-Ons',
  },
  EDIT: {
    id: 'edit',
    name: 'Edit Sale',
  },
  FLASH: {
    id: 'flash-sales',
    name: 'Flash Sales',
  },
  SHOP: {
    id: 'shop',
    name: 'The Shop',
  },
  UPCOMING: {
    id: 'upcoming',
    name: 'Upcoming Order',
  },
};

export const getCampaign = (activeCampaign, isNewMember = false) => {
  if (activeCampaign && isPreviewingCampaign) return TABS[activeCampaign.type];
  if (isNewMember && activeCampaign?.type === SALE_TYPES.EDIT) return TABS.UPCOMING;
  if (activeCampaign && activeCampaign.type === SALE_TYPES.SEASON) return TABS.SEASON;
  if (activeCampaign && activeCampaign.type === SALE_TYPES.EDIT) {
    return TABS.EDIT;
  }

  return TABS.FLASH;
};
