// extracted by mini-css-extract-plugin
export var button = "LoadingCart_button__OADrU";
export var footer = "LoadingCart_footer__smUlF";
export var image = "LoadingCart_image__JC1h1";
export var price = "LoadingCart_price__OSpIS";
export var progress = "LoadingCart_progress__iwthJ";
export var row = "LoadingCart_row__MkRA+";
export var tab = "LoadingCart_tab__iPHU8";
export var tabContainer = "LoadingCart_tabContainer__QEzIT";
export var title = "LoadingCart_title__Ds22d";
export var titlesContainer = "LoadingCart_titlesContainer__NUDWy";
export var total = "LoadingCart_total__k536+";
export var totalContiner = "LoadingCart_totalContiner__OhPJt";