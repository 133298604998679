export const isTargetingAllowed = () => window?.OnetrustActiveGroups?.includes('C0004');

export const loadCookieConsentScript = () => {
  if (window?.OneTrust !== undefined) return;
  if (typeof document !== 'undefined') {
    const OTTag = document.createElement('script');
    OTTag.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
    OTTag.type = 'text/javascript';
    OTTag.charset = 'UTF-8';
    OTTag.setAttribute('data-domain-script', '091bf775-529d-4036-a445-3a322675c69c');
    document.head.appendChild(OTTag);

    if (!window.OptanonWrapper) {
      const optWrapperScript = document.createElement('script');
      optWrapperScript.type = 'text/javascript';
      optWrapperScript.text = 'function OptanonWrapper() {}';
      document.head.appendChild(optWrapperScript);
    }
  }
};
