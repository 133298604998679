import type { FunctionComponent } from 'react';
import InternalContextProvider from './context/InternalContext';
import CartContainer from './scenes/Cart/CartContainer';

export const CartComponent: FunctionComponent<{
  env: string;
  newMemberState: string | null | undefined;
  updateInternalNewMemberState: (state: string) => void;
}> = ({ env, newMemberState, updateInternalNewMemberState }) => (
  <InternalContextProvider env={env}>
    <CartContainer
      newMemberState={newMemberState}
      updateInternalNewMemberState={updateInternalNewMemberState}
    />
  </InternalContextProvider>
);
