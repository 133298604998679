import type { FunctionComponent } from 'react';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { Button } from '@fff-web/fff-ui-shared';
import type { EPayContext, IBillingMethods, IProspect, PlanCode } from '../../../interfaces';
import { APPLE_PAY, CREDIT_CARD, PAYPAL, VENMO } from '../../utils/constants';
import { getProspectContextLogs } from '../../utils/getProspectContextLogs';
import ApplePayButton from './ApplePayButton';
import PayPalButton from './PayPalButton';
import VenmoButton from './VenmoButton';
import bbbTestImage from '../../assets/bbbTest.png';
import { ReactComponent as LockIcon } from '../../assets/lock.svg';
import * as styles from './SubmitBtn.module.css';

interface SubmitBtnProps {
  isDisabled: boolean;
  billingMethod: IBillingMethods;
  handleSubmit: () => void;
  payContext: EPayContext;
  prospect: IProspect;
  planCode?: PlanCode;
}

const ctaStyles = {
  borderRadius: '6px',
  maxWidth: '100%',
  boxShadow: 'none',
  width: '100%',
};

const ctaStylesDisabled = {
  ...ctaStyles,
  cursor: 'not-allowed',
};
const GTB = 'gtb';

const SubmitBtn: FunctionComponent<SubmitBtnProps> = ({
  isDisabled,
  billingMethod,
  handleSubmit,
  payContext = GTB,
  prospect,
  planCode,
}) => {
  const isVenmoBilling = billingMethod === VENMO;
  const buttonText = payContext === GTB ? 'ORDER THE BOX' : 'Place Order';

  const handleSubmitClick = () => {
    datadogLogs.logger.info(
      `Click on ${billingMethod} submit button`,
      getProspectContextLogs({ ...prospect, planCode }),
    );
    datadogRum.addAction(
      `click on ${buttonText}`,
      getProspectContextLogs({ ...prospect, planCode }),
    );
    handleSubmit();
  };

  return (
    <div className={styles.ctaContainer}>
      {billingMethod === CREDIT_CARD && (
        <Button
          type="button"
          variation="rounded-primary"
          onClick={handleSubmitClick}
          styles={isDisabled ? ctaStylesDisabled : ctaStyles}
          disabled={isDisabled}
          data-dd-action-name="Submit CTA (Credit Card)"
          data-e2e="order-the-box"
        >
          <LockIcon className={styles.lockIcon} />
          {buttonText}
        </Button>
      )}
      {billingMethod === PAYPAL && (
        <PayPalButton disabled={isDisabled} handlePayPal={handleSubmitClick} />
      )}
      {billingMethod === APPLE_PAY && (
        <ApplePayButton disabled={isDisabled} handleApplePay={handleSubmitClick} />
      )}
      {isVenmoBilling && <VenmoButton disabled={isDisabled} handleVenmo={handleSubmitClick} />}
      <div className={styles.bbbContainer}>
        <img src={bbbTestImage} alt="BBB" width="138px" height="56px" />
      </div>
      {}
      <img
        alt="secure"
        className={styles.secureImg}
        src="https://scontent01.fabfitfun.com/gtb/cdn/checkout-secure.webp"
      />
    </div>
  );
};

export default SubmitBtn;
