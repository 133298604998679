import {
  INVITE_FRIENDS_ID_ROUTE,
  INVITE_ID_ROUTE,
  REACTIVATION_URL,
  RR_ID_ROUTE,
  SUBSCRIPTION_STATE,
} from '../utils/constants';

export const formatRoutes = (routes, subscriptionState, RRFlag) => {
  const result = [];
  routes.forEach((route) => {
    const { id } = route;
    if (
      (id === INVITE_ID_ROUTE || id === INVITE_FRIENDS_ID_ROUTE) &&
      subscriptionState === SUBSCRIPTION_STATE.expired
    ) {
      result.push({ ...route, route: REACTIVATION_URL });
      return;
    }
    if ((RRFlag && id === RR_ID_ROUTE) || id !== RR_ID_ROUTE) {
      result.push(route);
    }
  });
  return result;
};
