import { FFFStorage } from '@fff-web/fff-utilities';

const routesExpirationTimeInMs = 10 * 60 * 1000; // 10 minutes;
const routesKey = 'navigation-gn';

export const invalidateMenuCache = () => {
  FFFStorage.removeFromStorage({ key: routesKey });
};

export const validateNavigationPayload = (payload) =>
  payload &&
  typeof payload === 'object' &&
  Array.isArray(payload.loginState) &&
  Array.isArray(payload.logoutState) &&
  Array.isArray(payload.userProfile);

export const getRoutesFromLocalStorage = () => {
  const savedNavigation = FFFStorage.getFromStorage({ key: routesKey });
  if (savedNavigation && validateNavigationPayload(savedNavigation)) {
    return savedNavigation;
  }
  return { loginState: [], logoutState: [], userProfile: [] };
};

export const getRoutes = (env) =>
  new Promise((resolve, reject) => {
    const navigation = FFFStorage.getFromStorage({ key: routesKey });
    if (navigation) {
      // TODO: This function is a promise but we aren't doing a promise here.

      return navigation;
    }

    return fetch(`${env.REACT_APP_NAVIGATOR_URL}/menu`, { credentials: 'include' })
      .then((response) => response.json())
      .then((json) => {
        FFFStorage.setToStorage({
          key: routesKey,
          value: json,
          durationInMs: routesExpirationTimeInMs,
        });
        resolve(json);
      })
      .catch((res) => {
        reject(res);
      });
  });

export const getDismissBannerKey = (bannerId) => `dismiss_${bannerId}`;
export const isDismissByBannerId = (bannerId) => {
  const dismiss = FFFStorage.getFromStorage({
    key: getDismissBannerKey(bannerId),
  });
  return dismiss === 'yes';
};

export const getBanner = (env) =>
  fetch(env.REACT_APP_BANNER_URL).then((response) => {
    if (response.status === 404 || response.status === 204) {
      const notPublishResponse = {
        subText: '404',
        published: false,
        id: 0,
        title: '404',
        createdAt: '',
        lastEditedAt: '',
        lastEditedBy: '',
        hideBanner: true,
      };
      FFFStorage.setToStorage({ key: 'banner', value: notPublishResponse });
      return notPublishResponse;
    }
    if (response.ok) {
      return response.json().then((json) => {
        const banner = {
          ...json,
          hideBanner: isDismissByBannerId(json.id) || !json.published,
        };
        FFFStorage.setToStorage({ key: 'banner', value: banner });
        return banner;
      });
    }
    throw response;
  });

export const getTimer = (env) =>
  fetch(env.REACT_APP_TIMER_URL, { credentials: 'include' }).then((response) => {
    if (response.status === 204) {
      return null;
    }
    return response.json();
  });

export const getNewMemberData = (env) =>
  fetch(`${env.REACT_APP_NEW_MEMBER_API_URL}/new-members/timer/self`, {
    credentials: 'include',
  }).then((response) => response.json());
