import { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
// TODO: Do we need this import?
// eslint-disable-next-line unused-imports/no-unused-imports
import { FFFErrorBoundary, FontAwesome } from '@fff-web/fff-ui-shared';
import { getAccountInfo } from '@fff-web/fff-utilities';
import { useCartContext } from '../../context/CartContext';
import DataContextProvider from '../../context/DataContext';
import { useInternalContext } from '../../context/InternalContext';
import { fetchCampaignsData } from '../../services/apis/campaignRequests';
import { fetchCart, getPurchasedVariants } from '../../services/apis/cartRequests';
import { fetchCredits } from '../../services/apis/paymentRequests';
import useAnalytics from '../../shared/hooks/useAnalytics';
import { useBootstrapLD } from '../../shared/hooks/useBootstrapLD';
import CartButton from './CartButton/CartButton';
import CartContent from './CartContent/CartContent';
import CartOverlay from './CartOverlay/CartOverlay';
import NewItemAddedModal from './NewItemAddedModal/NewItemAddedModal';
import '../../index.module.css';
import { getActiveCampaign, getCampaignType, SALE_TYPES } from '../../services/utils/campaign';
import { CartOnboarding, NEW_MEMBER_STATE } from './CartOnboarding/CartOnboarding';
import * as styles from './CartContainer.module.css';

const INITIAL_CREDITS = {
  purchaseCredit: 0,
  season: {
    credit: 0,
  },
  edit: {
    credit: 0,
  },
};

//TODO: Please fix the types.
// eslint-disable-next-line react/prop-types
const CartContainer = ({ newMemberState, updateInternalNewMemberState }) => {
  const {
    account,
    cartData,
    receiveUpdateCart,
    onClickProduct,
    receiveCartProducts,
    seasonalPlanPrice,
    saleRefreshKey,
  } = useCartContext();
  const { page, isEditPage, isAddOnsPage, env, isShopPage, isBoostsPage } = useInternalContext();
  const [isOpen, setIsOpen] = useState(false);
  const [campaignData, setCampaignData] = useState(null);
  const [shopCartDataState, setShopCartDataState] = useState(null);

  const [saleCartDataState, setSaleCartDataState] = useState(null);
  const [userData, setUserData] = useState(account || {});
  const [userDataLoading, setUserDataLoading] = useState(true);
  const [initialCredits, setInitialCredits] = useState(INITIAL_CREDITS);
  //eslint-disable-next-line
  const [error, setError] = useState(null);

  const isPublicShopping = !account?.loggedIn && isShopPage;

  const activeCampaign = useMemo(
    () =>
      isPublicShopping
        ? campaignData
        : getActiveCampaign(
            campaignData && campaignData.season,
            campaignData && campaignData.edit,
            isAddOnsPage,
            isBoostsPage,
            isEditPage,
          ),
    [campaignData],
  );

  const itemsCount =
    activeCampaign && !isShopPage
      ? _.get(saleCartDataState, 'itemsCount')
      : _.get(shopCartDataState, 'itemsCount');

  const {
    freeTierShopAccessFF,
    freeTierShopAccessReady,
    shoppingCreditFF,
    shoppingCreditReady,
    alternateSubscriptionsReady,
    selfCheckoutFF,
    selfCheckoutReady,
    flashSaleDiscountCouponsFF,
    flashSaleDiscountCouponsReady,
  } = useBootstrapLD(env, _.get(userData, 'userId'), userDataLoading);

  const handleCloseCart = () => setIsOpen(false);
  const toggleCart = () => setIsOpen((prevState) => !prevState);

  useAnalytics(env);

  // get campaign data and initial credits
  useEffect(() => {
    const getUserData = async () => {
      const response = await getAccountInfo(env);
      setUserData(response);
      setUserDataLoading(false);
    };
    const getCampaignData = async () => {
      try {
        const campaignType = getCampaignType(page);
        const campaigns = await fetchCampaignsData(campaignType, env, isPublicShopping);
        setCampaignData(campaigns);
      } catch (err) {
        setError(err);
      }
    };
    const getInitialCredits = async () => {
      try {
        const credits = await fetchCredits(env);
        setInitialCredits(credits);
      } catch (err) {
        setError(err);
      }
    };
    getCampaignData();
    if (!isPublicShopping) {
      if (!account) getUserData();
      getInitialCredits();
    }
  }, [isPublicShopping]);
  // get sale cart data (add-ons or edit campaign)
  useEffect(() => {
    // return live sale based on userAccess of activeCampaign
    const getLiveSale = () => {
      if (activeCampaign?.userAccess) return activeCampaign.type;
      return null;
    };
    const getSaleCartData = async () => {
      try {
        const liveSale = getLiveSale();
        if (liveSale) {
          const saleCart = await fetchCart(liveSale, isEditPage || isAddOnsPage, env);
          const { cartDetails, count, cartId, customizeCampaignDetails } = saleCart;
          let { cartVariants } = saleCart;
          const { role } = userData;
          const { campaignWindow, id, type } = campaignData[_.lowerCase(liveSale)];
          if (campaignWindow === 2 && role === 'SELECT') {
            cartVariants = await getPurchasedVariants(id, type, cartVariants, env);
          }
          setSaleCartDataState({
            cartVariants,
            cartDetails,
            itemsCount: count,
            cartId,
            customizeCampaignDetails,
          });
        } else {
          // added below lines for loading state. Avoid to be null if sale page is closed
          setSaleCartDataState({
            cartVariants: [],
            cartDetails: {},
            itemsCount: 0,
            cartId: null,
            customizeCampaignDetails: null,
          });
        }
      } catch (err) {
        setError(err);
      }
    };
    if (campaignData && !isPublicShopping) getSaleCartData();
  }, [campaignData, userData, saleRefreshKey]);

  // get shop cart data
  useEffect(() => {
    const getShopCartData = async () => {
      try {
        const shopCart = await fetchCart(SALE_TYPES.SHOP, isShopPage, env, isPublicShopping);
        const { cartDetails, cartVariants, count, cartId } = shopCart;
        setShopCartDataState({
          cartVariants,
          cartDetails,
          itemsCount: count,
          cartId,
        });
      } catch (err) {
        // added to avoid being null if shop is "closed" - user expired
        // without this the cart will be stuck in loading state
        setShopCartDataState({
          cartVariants: [],
          cartDetails: {},
          itemsCount: 0,
          cartId: null,
        });
        setError(err);
      }
    };
    getShopCartData();
  }, [isPublicShopping]);

  useEffect(() => {
    if (cartData) {
      if (isShopPage) setShopCartDataState(cartData);
      else if (cartData?.cartId) setSaleCartDataState(cartData);
    }
  }, [cartData]);

  useEffect(() => {
    if (account) {
      setUserData(account);
      setUserDataLoading(false);
    }
  }, [account, isPublicShopping]);

  const isNewMember = !!newMemberState;
  const isNewMemberFlow = isNewMember && Object.values(NEW_MEMBER_STATE).includes(newMemberState);

  const isLoading = isPublicShopping
    ? !shopCartDataState
    : !campaignData ||
      !shopCartDataState ||
      !saleCartDataState ||
      !freeTierShopAccessReady ||
      !shoppingCreditReady ||
      !alternateSubscriptionsReady ||
      !flashSaleDiscountCouponsReady ||
      !selfCheckoutReady;

  return (
    <FFFErrorBoundary fallbackUI={<></>}>
      <DataContextProvider
        userData={userData}
        initialCredits={initialCredits}
        onClickProduct={onClickProduct}
        shoppingCreditFF={shoppingCreditFF}
        isNewMember={isNewMember}
        isNewMemberFlow={isNewMemberFlow}
        newMemberState={newMemberState}
      >
        {isOpen && <CartOverlay closeCart={handleCloseCart} />}
        <div className={styles.container}>
          {isPublicShopping && shopCartDataState && (
            <NewItemAddedModal
              cartData={shopCartDataState}
              handleOpenCart={() => setIsOpen(true)}
            />
          )}
          <CartButton itemsCount={itemsCount} toggleCart={toggleCart} />
          {isOpen && (
            <CartContent
              activeCampaign={activeCampaign}
              shopCampaignData={campaignData?.shop}
              isLoading={isLoading}
              saleCartDataState={saleCartDataState}
              setSaleCartDataState={setSaleCartDataState}
              shopCartDataState={shopCartDataState}
              setShopCartDataState={setShopCartDataState}
              closeCart={handleCloseCart}
              receiveUpdateCart={receiveUpdateCart}
              receiveCartProducts={receiveCartProducts}
              freeTierShopAccessFF={freeTierShopAccessFF}
              isPublicShopping={isPublicShopping}
              selfCheckoutFF={selfCheckoutFF}
              flashSaleDiscountCouponsFF={flashSaleDiscountCouponsFF}
              itemsCount={itemsCount}
              seasonalPlanPrice={seasonalPlanPrice}
            />
          )}
        </div>
      </DataContextProvider>
      <CartOnboarding
        campaignData={campaignData}
        userId={userData?.userId}
        saleItemsCount={saleCartDataState?.itemsCount}
        isNewMember={isNewMember}
        newMemberState={newMemberState}
        updateInternalNewMemberState={updateInternalNewMemberState}
      />
    </FFFErrorBoundary>
  );
};

export default CartContainer;
