import { useEffect, useState } from 'react';
import { datadogLogs } from '@datadog/browser-logs';
import { getMembershipInfo } from '@fff-web/fff-utilities';

export type MembershipInfo = {
  membership_state: string;
  isRefillSubscription: boolean;
};

export function useMembershipInfo() {
  const [membershipInfo, setMembershipInfo] = useState<MembershipInfo>({} as MembershipInfo);

  useEffect(() => {
    try {
      getMembershipInfo().then((info) => setMembershipInfo(info));
    } catch (error) {
      datadogLogs.logger.error('Unable to get Membership Info', {}, error as Error);
      return;
    }
  }, []);

  return {
    membershipInfo,
  };
}

export default useMembershipInfo;
