import { useEffect, useRef, useState } from 'react';

const useInView = () => {
  const [isInView, setIsInView] = useState(false);
  const elementRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    const onChange = (entries: IntersectionObserverEntry[]) => {
      const element = entries[0];
      setIsInView(element.isIntersecting);
    };

    const observer = new IntersectionObserver(onChange, {
      rootMargin: '0px',
    });

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => observer.disconnect();
  });

  return { isInView, elementRef };
};

export default useInView;
