import { memo, useContext } from 'react';
import { bool, string } from 'prop-types';
import { getEnv } from '../environment';
import GlobalNavInternalContext from './context/GlobalNavInternalContext';
import OptimizelyProvider from './context/OptimizelyProvider';
import { UniversalNavigationContext } from './context/UniversalNavigationContext';
import GlobalNavContent from './GlobalNavContent';
import { useBootstrapLD } from './hooks/useBootstrapLD';

const GlobalNav = ({ env: envName = '', hideCart = false, ...props }) => {
  const env = getEnv(envName);
  const externalContextData = useContext(UniversalNavigationContext);
  const {
    downloadMobileAppWebBannerFF,
    downloadMobileAppWebBannerReady,
    ordersCtaFF,
    ordersCtaReady,
    webRatingsAndReviewInGlobalNavDropDownFF,
    webRatingsAndReviewInGlobalNavDropDownReady,
    ccToastNotificationFF,
    ccToastNotificationReady,
  } = useBootstrapLD(env.name);

  const contextValue = {
    env,
    mainProps: { hideCart, ...props },
    ...externalContextData,
    flags: {
      downloadMobileAppWebBannerFF,
      downloadMobileAppWebBannerReady,
      ordersCtaFF,
      ordersCtaReady,
      webRatingsAndReviewInGlobalNavDropDownFF,
      webRatingsAndReviewInGlobalNavDropDownReady,
      ccToastNotificationFF,
      ccToastNotificationReady,
    },
  };

  return (
    <OptimizelyProvider>
      <GlobalNavInternalContext.Provider value={contextValue}>
        <GlobalNavContent />
      </GlobalNavInternalContext.Provider>
    </OptimizelyProvider>
  );
};

GlobalNav.propTypes = {
  env: string,
  hideCart: bool,
};

export default memo(GlobalNav);
