import { createContext } from 'react';
import { getEnv } from '../../environment';

interface GlobalNavInternalContextFlags {
  downloadMobileAppWebBannerFF: boolean | null;
  downloadMobileAppWebBannerReady: boolean;
  ordersCtaFF: boolean | null;
  ordersCtaReady: boolean;
  webRatingsAndReviewInGlobalNavDropDownFF: boolean | null;
  webRatingsAndReviewInGlobalNavDropDownReady: boolean;
  ccToastNotificationFF: boolean | null;
  ccToastNotificationReady: boolean;
}

const GlobalNavInternalContext = createContext<{
  env: ReturnType<typeof getEnv>;
  mainProps?: Record<string, unknown>;
  flags: GlobalNavInternalContextFlags;
}>({
  env: getEnv(),
  mainProps: {},
  flags: {} as GlobalNavInternalContextFlags,
});

export { type Timer } from './UniversalNavigationContext';
export default GlobalNavInternalContext;
