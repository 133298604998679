import { useCallback, useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useMediaQuery } from 'react-responsive';
import { Compare, useUserContext } from '@fff-web/fff-utilities';
import GlobalNavInternalContext from '../../context/GlobalNavInternalContext';
import { rearrangeRoutes } from '../../helpers/navigationMenu';
import { buildUserInfoRequest } from '../../helpers/userAdapter';
import { getRoutes, getRoutesFromLocalStorage, validateNavigationPayload } from '../../services';
import './Header.css';
import { useGlobalNavTimerRedirect } from '../../hooks/useGlobalNavTimerRedirect';
import HeaderContent from './HeaderContent';

const Header = () => {
  const userRequest = buildUserInfoRequest(useUserContext().userInfo);
  const { isLoggedIn, lives, userInfo } = userRequest;
  const [routes, setRoutes] = useState(getRoutesFromLocalStorage());
  const [isNavigationMenuVisible, setIsNavigationMenuVisible] = useState(false);
  const { env } = useContext(GlobalNavInternalContext);

  useGlobalNavTimerRedirect(routes.fffSocialConfig);

  const isDesktop = useMediaQuery({
    query: '(min-width: 992px)',
  });

  const handleSetRoutes = useCallback((newRoutes) => {
    if (validateNavigationPayload(newRoutes) && !Compare.isEqualObject(newRoutes, routes)) {
      setRoutes(newRoutes);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setIsNavigationMenuVisible(false);
    document.body.classList.remove('scrollContain');
  }, [isDesktop]);

  useEffect(() => {
    getRoutes(env)
      .then((newRoutes) => handleSetRoutes(newRoutes))
      .catch((error) => {
        console.error(error);
      });
  }, [env, handleSetRoutes]);

  const handleToggleNavigationMenuVisible = () => {
    if (!isDesktop) {
      setIsNavigationMenuVisible(!isNavigationMenuVisible);
      if (isNavigationMenuVisible) {
        document.body.classList.remove('scrollContain');
      } else {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        document.body.classList.add('scrollContain');
      }
    }
  };

  return (
    <div
      className={classNames('header', {
        'is-navigation-menu-visible': isNavigationMenuVisible,
      })}
    >
      <div className="header__wrapper">
        <HeaderContent
          isLoggedIn={isLoggedIn}
          isDesktop={isDesktop}
          handleToggleNavigationMenuVisible={handleToggleNavigationMenuVisible}
          isNavigationMenuVisible={isNavigationMenuVisible}
          routes={rearrangeRoutes(
            routes,
            lives,
            userInfo.subscriptionState,
            userInfo.subscriptionType,
          )}
          userInfo={userInfo}
        />
      </div>
    </div>
  );
};

Header.propTypes = {};

export default Header;
