/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useRef, useState, type FunctionComponent } from 'react';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import { Button, Modal } from '@fff-web/fff-ui-shared';
import { VARIANT_TYPES } from '../../../services/utils/types';
import * as styles from './NewItemAddedModal.module.css';

type NewItemAddedModalProps = {
  cartData: {
    cartDetails: {
      variantIds: string[];
    };
    cartVariants: CartVariant[];
  };
  handleOpenCart: () => void;
};

type CartVariant = {
  variantType: string;
  imageUrl: string;
  cartDisplayName: string;
  price: number;
  salePrice: number;
};

const NewItemAddedModal: FunctionComponent<NewItemAddedModalProps> = ({
  cartData,
  handleOpenCart,
}) => {
  const prevCardVariantsRef = useRef<number | null>(null);
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [displayItem, setDisplayItem] = useState<CartVariant | null>(null);
  const priceToShow =
    displayItem?.variantType === VARIANT_TYPES.prospectSingle
      ? displayItem?.price
      : displayItem?.salePrice;

  const handleClickViewCart = () => {
    isMobile && history.push('/shop');
    setIsOpen(false);
    handleOpenCart();
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handdleDisplayModal = () => {
    const { cartDetails, cartVariants } = cartData;
    if (!cartDetails || !cartVariants || !cartDetails?.variantIds) return;

    const currentCartVariantsIds = cartDetails?.variantIds;
    if (
      prevCardVariantsRef.current !== null &&
      currentCartVariantsIds.length > prevCardVariantsRef.current
    ) {
      setDisplayItem(cartVariants[cartVariants.length - 1]);
      setIsOpen(true);
    }
    prevCardVariantsRef.current = currentCartVariantsIds.length;
  };

  useEffect(() => {
    const timeout = setTimeout(
      () => {
        handdleDisplayModal();
      },
      prevCardVariantsRef.current === null ? 700 : 50,
    );

    return () => clearTimeout(timeout);
  }, [cartData]);

  if (!displayItem || !isOpen) return null;

  const isRefillProduct = displayItem.variantType === 'REFILL';

  const content = () => (
    <div className={styles.contentContainer}>
      <div className={styles.headerContainer} data-e2e="added-to-your-cart">
        Added to Your Cart
        <button type="button" onClick={handleClose} data-e2e="close-added-to-your-cart-button">
          <i className="fal fa-times" />
        </button>
      </div>
      <div className={styles.itemContainer}>
        <div className={styles.imageAndDescriptionContainer}>
          <img src={displayItem?.imageUrl} alt="" />
          <div className={styles.itemDescription}>
            <p>{displayItem?.cartDisplayName}</p>
            {isRefillProduct && <p>Ships every 90 days</p>}
          </div>
        </div>
        <p className={styles.price}>${Number(priceToShow).toFixed(2)}</p>
      </div>
      <div className={styles.buttonsContainer}>
        <Button variation="primary-outline" onClick={handleClickViewCart}>
          View Cart
        </Button>
        <Button variation="primary" data-e2e="added-modal-checkout-button">
          <a href="/checkout">Checkout</a>
        </Button>
      </div>
      <p className={styles.disclaimer}>
        Enjoy <strong>free shipping</strong> on orders $49 or more in the contiguous US
      </p>
    </div>
  );

  if (isMobile)
    return (
      <Modal isOpen className={styles.modalPortal} onRequestClose={handleClose}>
        {content()}
      </Modal>
    );

  return (
    <div className={styles.modal}>
      {/* TODO: Please fix the a11y issues with this element and then remove the comments at the top of the file. */}
      <div className={styles.overlay} onClick={handleClose} />
      {content()}
    </div>
  );
};

export default NewItemAddedModal;
