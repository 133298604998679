import { getEnv } from '../environment';
import request from './request';

const env = getEnv();
const { REACT_APP_USER_URL: userApi } = env;

interface SendPhoneNumberResponse {
  nonUserUuid: string;
}

export const createUser = (email: string) => {
  return request({
    path: `${userApi}/leads/create`,
    method: 'POST',
    body: JSON.stringify({
      email,
    }),
  });
};

export const sendPhoneNumber = (
  phoneNumber: string,
  email?: string,
): Promise<SendPhoneNumberResponse | undefined> => {
  const formattedNumber = phoneNumber.replace(/[^\d+]/g, '');

  return request({
    path: `${userApi}/leads/create`,
    method: 'POST',
    body: JSON.stringify({
      phoneNumber: formattedNumber,
      ...(email ? { email } : {}),
    }),
  }).then((response) => {
    if (typeof response === 'string') {
      return;
    }
    return response as SendPhoneNumberResponse;
  });
};
