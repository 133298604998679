import { useEffect, useState } from 'react';
import * as LDClient from 'launchdarkly-js-client-sdk';
import { getLDClientId } from '../../services/utils/getLdClientId';

/**
 * Hook to bootstrap LaunchDarkly flags
 * @param {string} env - The environment
 * @param {string} userId - The user ID
 * @param {boolean} userDataLoading - Whether the user data is loading
 * @returns {object} - The LaunchDarkly flags
 */
export function useBootstrapLD(env, userId, userDataLoading) {
  const [freeTierShopAccessFF, setFreeTierShopAccessFF] = useState(null);
  const [freeTierShopAccessReady, setFreeTierShopAccessReady] = useState(false);
  const [shoppingCreditFF, setShoppingCreditFF] = useState(false);
  const [shoppingCreditReady, setShoppingCreditReady] = useState(false);
  const [alternateSubscriptionsReady, setAlternateSubscriptionsReady] = useState(false);
  const [selfCheckoutFF, setSelfCheckoutFF] = useState(false);
  const [selfCheckoutReady, setSelfCheckoutReady] = useState(false);
  const [flashSaleDiscountCouponsFF, setFlashSaleDiscountCouponsFF] = useState(false);
  const [flashSaleDiscountCouponsReady, setFlashSaleDiscountCouponsReady] = useState(false);

  const clientId = getLDClientId(env);

  useEffect(() => {
    if (!userDataLoading) {
      const ldclient = LDClient.initialize(clientId, {
        key: userId || 'anonymous',
      });
      ldclient.on('ready', () => {
        const shoppingCreditLD = ldclient.variation('shopping-credit', false);
        setShoppingCreditFF(shoppingCreditLD);
        setShoppingCreditReady(true);
        const freeTierShopAccessLD = ldclient.variation('free-tier-evergreen-shop-access', false);
        setFreeTierShopAccessFF(freeTierShopAccessLD);
        setFreeTierShopAccessReady(true);
        setAlternateSubscriptionsReady(true);
        const selfCheckout = ldclient.variation('self-checkout', false);
        setSelfCheckoutFF(selfCheckout);
        setSelfCheckoutReady(true);
        const flashSaleDiscountCoupons = ldclient.variation('flash-sale-discount-coupons', false);
        setFlashSaleDiscountCouponsFF(flashSaleDiscountCoupons);
        setFlashSaleDiscountCouponsReady(true);
      });
      ldclient.on('change:shopping-credit', (value) => {
        setShoppingCreditFF(value);
      });
    }
  }, [userId, userDataLoading]);

  return {
    freeTierShopAccessFF,
    freeTierShopAccessReady,
    shoppingCreditFF,
    shoppingCreditReady,
    alternateSubscriptionsReady,
    selfCheckoutFF,
    selfCheckoutReady,
    flashSaleDiscountCouponsFF,
    flashSaleDiscountCouponsReady,
  };
}
export default useBootstrapLD;
