import { useCallback, useEffect, useState } from 'react';
import { useUniversalNavigationContext } from '@fff-web/fff-global-nav';

const useUserWay = () => {
  const { isAccessibilityButtonVisible } = useUniversalNavigationContext();
  const [isButtonAppended, setIsButtonAppended] = useState(false);
  const [userAccessibilityButtonNode, setUserAccessibilityButtonNode] =
    useState<HTMLElement | null>(null);

  const appendUserWayButton = useCallback(() => {
    const s = document.createElement('script');
    s.setAttribute('data-account', 'jcEETxr6Cv');
    s.setAttribute('src', 'https://accessibilityserver.org/widget.js');
    (document.body || document.head).appendChild(s);
    setIsButtonAppended(true);
  }, [setIsButtonAppended]);

  useEffect(() => {
    if (isButtonAppended) return;
    appendUserWayButton();
  }, [isButtonAppended, appendUserWayButton]);

  useEffect(() => {
    if (!userAccessibilityButtonNode) return;
    userAccessibilityButtonNode.style.display = isAccessibilityButtonVisible ? 'block' : 'none';
    userAccessibilityButtonNode.style.visibility = isAccessibilityButtonVisible
      ? 'visible'
      : 'hidden';
  }, [userAccessibilityButtonNode, isAccessibilityButtonVisible]);

  useEffect(() => {
    if (!isButtonAppended || !!userAccessibilityButtonNode) return;
    const accessibilityButtonNodeTimer = setTimeout(() => {
      const userAccessibilityButtonNode =
        document.getElementsByClassName('userway_buttons_wrapper');
      setUserAccessibilityButtonNode((userAccessibilityButtonNode?.[0] as HTMLElement) || null);
    }, 1000);

    return () => !!accessibilityButtonNodeTimer && clearTimeout(accessibilityButtonNodeTimer);
  }, [setUserAccessibilityButtonNode, isButtonAppended, userAccessibilityButtonNode]);
};

export default useUserWay;
