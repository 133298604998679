import cx from 'classnames';
import { arrayOf, bool, func, node, oneOfType, shape, string } from 'prop-types';
import ReadMore from '../ReadMore';
import BoxTimeline from '../Timeline/BoxTimeline';
import AddressDetails from './AddressDetails';
import BoxImage from './BoxImage';
import { NOT_SUBSCRIBED } from './boxStatusId';
import BoxTextBodyLinks from './BoxTextBodyLinks';
import { isAddressVisible } from './helpers';
import ReviewButton from './ReviewButton';
import StatusIcon from './StatusIcon';

const ChallengeBoxDesktop = ({
  wizmoMode,
  showAddressDetails = false,
  name,
  id,
  title,
  body,
  bodyDetail,
  trackingUrl,
  screenMode = 'mobile',
  orderSteps,
  fail,
  showReviewButton = false,
  currentOrderStep,
  shippingAddress,
  imageURL,
  RightContent = null,
  trackElements = () => null,
  showServiceLink = false,
}) => (
  <section className="challenge-container">
    <div className="challenge-status-container-desktop">
      {wizmoMode && <span>{`${fail || !name ? 'BOX ' : name} STATUS`}</span>}
      <div className="challenge-status-desktop">
        <BoxImage imageURL={imageURL} isMobile={false} />
        <BoxTimeline
          nodes={orderSteps}
          currentStep={currentOrderStep}
          trackingUrl={trackingUrl}
          isMobile={false}
        />
        {!['mobile', 'tablet', 'desktop'].includes(screenMode) && RightContent && (
          <RightContent statusId={id} />
        )}
      </div>
      <div
        className={cx({
          'status-details-desktop-not-sub': id === NOT_SUBSCRIBED,
          'status-details-desktop': id !== NOT_SUBSCRIBED,
          'with-address': isAddressVisible(id) && !wizmoMode,
        })}
      >
        <div className="challenge-status__details-desktop">
          {!wizmoMode && <span>{`${fail || !name ? 'BOX ' : name} STATUS`}</span>}
          {!!title && <h3>{title}</h3>}
          <p className={cx('challenge-status__body-desktop', { wizmoMode })}>
            {wizmoMode && <StatusIcon type={id} />}
            {body}
          </p>
          {bodyDetail && (
            <div className="challenge-status__body-detail-desktop">
              <ReadMore trackElements={trackElements}>{bodyDetail}</ReadMore>
            </div>
          )}
          <BoxTextBodyLinks
            boxStatusId={id}
            trackingUrl={trackingUrl}
            title={title}
            showServiceLink={showServiceLink}
            trackElements={trackElements}
          />
          {showReviewButton && <ReviewButton desktop trackElements={trackElements} />}
        </div>
        {(showAddressDetails || !wizmoMode) && (
          <div className="address-details-desktop">
            <AddressDetails shippingAddress={shippingAddress} hasFailed={fail} boxStatusId={id} />
          </div>
        )}
      </div>
      {['mobile', 'tablet', 'desktop'].includes(screenMode) && (
        <div className="tablet-links">{RightContent && <RightContent statusId={id} />}</div>
      )}
    </div>
    <div className="challenge-last-divider" />
  </section>
);

ChallengeBoxDesktop.propTypes = {
  screenMode: string,
  showReviewButton: bool,
  RightContent: oneOfType([node, func]),
  trackElements: func,
  showServiceLink: bool,
  showAddressDetails: bool,
  wizmoMode: bool,
  name: string,
  id: string,
  title: string,
  body: string,
  bodyDetail: string,
  trackingUrl: string,
  orderSteps: arrayOf(shape),
  fail: bool,
  currentOrderStep: string,
  shippingAddress: shape,
  imageURL: string,
};

export default ChallengeBoxDesktop;
