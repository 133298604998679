import { useContext, useEffect, useMemo, useState, type FunctionComponent } from 'react';
import { isMobile } from 'react-device-detect';
import { identify, track } from '@fff-web/fff-analytics';
import { useUserContext } from '@fff-web/fff-utilities';
import GlobalNavInternalContext from '../../context/GlobalNavInternalContext';
import { MOBILE_BANNER_VARIATIONS, OPTIMIZELY_FEATURES_FLAGS } from '../../utils/configurations';
import { VARIATIONS } from '../../utils/constants';
import { getAppMobileLink } from '../../utils/dynamicLinks';
import WithABTest from '../WithABTest';
import NewMobileBanner from './NewMobileBanner/NewMobileBanner';
import OldMobileBanner from './OldMobileBanner/OldMobileBanner';
import type { MobileWebBannerProps } from './types';

const TRACK_DEVICE = 'Mobile Web';
const TRACK_EXPERIENCE = 'smart app banner';
const CTA_BUTTON_TEXT = 'Open';
const BLACKLIST = ['reactivate-my-membership', 'customize', 'boosts'];

const MobileWebBanner: FunctionComponent<MobileWebBannerProps> = ({
  experimentName,
  variationName,
  isVariations,
}) => {
  const [isMobileBannerOpen, toggleMobileBanner] = useState(true);
  const { userInfo } = useUserContext();
  const { env, flags } = useContext(GlobalNavInternalContext);
  const { downloadMobileAppWebBannerFF: downloadMobileAppBanner } = flags;
  const isLoggedIn = Boolean(userInfo?.loggedIn);
  const appMobileLink = getAppMobileLink(env);
  const showABTest = variationName === VARIATIONS.ON || isVariations;

  const pathname = useMemo(() => window?.location?.pathname || '', []);
  const isOnBlackList = useMemo(
    () => BLACKLIST.some((path) => pathname.includes(path)),
    [pathname],
  );

  const showMobileBanner =
    isMobileBannerOpen &&
    downloadMobileAppBanner &&
    isMobile &&
    !isOnBlackList &&
    showABTest &&
    isLoggedIn;

  const handleBannerClose = () => {
    toggleMobileBanner(false);
    track({
      trackName: 'Web Banner Closed',
      properties: {
        cta_name: 'x',
        experience: TRACK_EXPERIENCE,
        invite_type: TRACK_DEVICE,
      },
    });
  };

  const handleBannerOpen = () => {
    window.location.href = appMobileLink;
    track({
      trackName: 'App Download Started',
      properties: {
        cta_name: CTA_BUTTON_TEXT,
        user_id: userInfo?.user?.userId,
        experience: TRACK_EXPERIENCE,
        invite_type: TRACK_DEVICE,
        position_location: appMobileLink,
      },
    });
  };

  const bannerProps = {
    onBannerClick: handleBannerOpen,
    onBannerClose: handleBannerClose,
  };

  useEffect(() => {
    identify({
      traits: {
        featureFlagName: OPTIMIZELY_FEATURES_FLAGS.SMART_BANNER_DOWNLOAD,
        experimentName,
        variationName,
      },
    });
    track({
      trackName: 'Experiment Viewed',
      properties: {
        feature_flag_name: OPTIMIZELY_FEATURES_FLAGS.SMART_BANNER_DOWNLOAD,
        experiment_name: experimentName,
        variation_name: variationName,
      },
    });
  }, [experimentName, variationName]);

  if (!showMobileBanner) return null;
  if (variationName === MOBILE_BANNER_VARIATIONS.VERSION_2) {
    return <NewMobileBanner {...bannerProps} />;
  }
  return <OldMobileBanner {...bannerProps} />;
};

export default WithABTest(OPTIMIZELY_FEATURES_FLAGS.SMART_BANNER_DOWNLOAD, MobileWebBanner, [
  MOBILE_BANNER_VARIATIONS.VERSION_1,
  MOBILE_BANNER_VARIATIONS.VERSION_2,
]);
