import { FunctionComponent } from 'react';
import type { OldMobileBannerProps } from './types';
import fffAppLogo from './fff-app-logo.png';
import * as styles from './OldMobileBanner.module.css';

const CTA_BUTTON_TEXT = 'Open';

const OldMobileBanner: FunctionComponent<OldMobileBannerProps> = ({
  onBannerClose,
  onBannerClick,
}) => {
  return (
    <div className={styles.container} id="mobile-web-banner">
      <div className={styles.appContainer}>
        <button
          type="button"
          onClick={onBannerClose}
          className={styles.dbClose}
          data-testid="mobile-banner-close-button"
        >
          <p className={styles.dbCloseIcon}>
            <i className="far fa-times" />
          </p>
        </button>
        <img src={fffAppLogo} alt="app-logo" className={styles.logoImage} />
        <div className={styles.details}>
          <div className={styles.title}>FabFitFun</div>
          <div className={styles.description}>Open in the FabFitFun app</div>
        </div>
      </div>
      <button
        type="button"
        onClick={onBannerClick}
        className={styles.openButton}
        data-testid="mobile-banner-open-button"
      >
        {CTA_BUTTON_TEXT.toUpperCase()}
      </button>
    </div>
  );
};

export default OldMobileBanner;
