import { CountryLocationApiResponse } from '../../interfaces/ICountryLocationApiResponse';
import { getEnv } from '../environment';
import request from './request';

const env = getEnv();
const { REACT_APP_USER_URL: userApi, REACT_APP_GROWTH_URL: growthApi } = env;

export const getAddresses = () => request({ path: `${userApi}/self/addresses` });

export const getAddress = (id: string) => request({ path: `${userApi}/self/addresses/${id}` });

export const updateAddress = (payload: unknown, id: number) =>
  request({
    path: `${userApi}/self/addresses/${id}`,
    method: 'PUT',
    body: JSON.stringify(payload),
  });

export const addAddress = (payload: unknown) =>
  request({
    path: `${userApi}/self/addresses`,
    method: 'POST',
    body: JSON.stringify(payload),
  });

export const removeAddress = (addressId: number) =>
  request({
    path: `${userApi}/self/addresses/${addressId}`,
    method: 'DELETE',
  });

export const verifyAddress = (payload: unknown) =>
  request({
    path: `${growthApi}/location/verify-address`,
    method: 'POST',
    body: JSON.stringify(payload),
  });

export const getCountryIsoCode = () =>
  request({
    path: `${growthApi}/location/country-by-ip`,
  }) as Promise<CountryLocationApiResponse>;
