// extracted by mini-css-extract-plugin
export var buttonsContainer = "NewItemAddedModal_buttonsContainer__l42OO";
export var contentContainer = "NewItemAddedModal_contentContainer__QPsd5";
export var disclaimer = "NewItemAddedModal_disclaimer__AoTAx";
export var headerContainer = "NewItemAddedModal_headerContainer__qVwdx";
export var imageAndDescriptionContainer = "NewItemAddedModal_imageAndDescriptionContainer__zYRXm";
export var itemContainer = "NewItemAddedModal_itemContainer__Ez3-m";
export var itemDescription = "NewItemAddedModal_itemDescription__8jDTb";
export var modal = "NewItemAddedModal_modal__YLz+t";
export var modalPortal = "NewItemAddedModal_modalPortal__r+mAZ";
export var overlay = "NewItemAddedModal_overlay__uWKkn";
export var price = "NewItemAddedModal_price__LptPf";
export var shared = "\"../../../shared/shared.module.css\"";
export var sm = "576px";