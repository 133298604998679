import { useCallback } from 'react';
import { ReferrerQueryString, track, trackElement } from '@fff-web/fff-analytics';
import { useUserContext, useViewSize } from '@fff-web/fff-utilities';
import { FooterLink, LinkShape } from '../../services/types';
import { SUBSCRIPTION_STATE } from '../../utils/constants';
import { filterSwapElement } from '../../utils/filterSwapElement';
import { AccordionFooterLink } from '../AccordionFooterLink';
import './LinkFooter.scss';
import { useHistory } from 'react-router-dom';
import useSubscriptionPlans from '../../hooks/useSubscriptionPlans';
import { LinkItem } from '../LinkItem/LinkItem';

interface Props {
  categories: FooterLink[];
  hideSwap?: boolean;
}

const LinkFooter = ({ categories, hideSwap }: Props) => {
  const { isMobile } = useViewSize();
  const history = useHistory();
  const { subscriptionPlans } = useSubscriptionPlans();
  const {
    userInfo: { user },
  } = useUserContext();

  const plan = subscriptionPlans?.[user.planCode];
  const isSubscriptionExpired = user?.subscriptionState === SUBSCRIPTION_STATE.expired;

  const clickListener = useCallback(
    (buildedPath: string, label: string, trakingName?: string) => {
      if (label === 'Swap for Credit') {
        const path = `${buildedPath}?referrer=${ReferrerQueryString.footerLink}`;
        track({
          trackName: 'Swap Started',
          properties: {
            swap_amount: plan?.boxPrice?.amount,
            plan_code: user?.planCode,
            cta_name: label,
            experience: 'Footer Link',
          },
          callback: () => {
            window.location.href = path;
          },
        });
      } else if (trakingName) {
        trackElement({
          elementName: trakingName,
          callback: () => {
            window.location.href = buildedPath;
          },
        });
      }
    },
    [plan?.boxPrice?.amount, user?.planCode],
  );

  const handleOnClickMenuLink = ({ label, trakingName }: LinkShape, path: string) => {
    clickListener(path, label, trakingName);
    history.push(path);
  };

  return (
    <div className="fff-shared-linkFooter-container">
      {categories
        .filter(({ links }) =>
          hideSwap || isSubscriptionExpired ? filterSwapElement(links) : links,
        )
        .map(({ id, header, links }) => (
          <div className="fff-shared-linkFooter-item" key={id}>
            {isMobile ? (
              <AccordionFooterLink
                key={id}
                header={header}
                items={links}
                handleOnClickMenuLink={handleOnClickMenuLink}
              />
            ) : (
              <div className="fff-shared-linkFooter-column">
                <span className="fff-shared-linkFooter-column-header">{header}</span>
                {links.map((item) => (
                  <LinkItem
                    key={item.id}
                    link={item}
                    className="fff-shared-linkFooter-column-item"
                    handleOnClick={handleOnClickMenuLink}
                  />
                ))}
              </div>
            )}
          </div>
        ))}
    </div>
  );
};

export { LinkFooter };
