import type { FunctionComponent } from 'react';
import { Button } from '@fff-web/fff-ui-shared';
import { useDataContext } from '../../../context/DataContext';
import { useInternalContext } from '../../../context/InternalContext';

const getCheckoutCTAText = (isEditCampaign: boolean, isNewMember: boolean) => {
  if (isEditCampaign && isNewMember) {
    return 'Submit Order';
  }
  return 'Proceed To Checkout';
};

type CheckoutButtonProps = {
  isShopCampaign: boolean;
  isEditCampaign: boolean;
  isPublicShopping: boolean;
  displayEditCheckout: boolean;
  trackCheckout: (cta: string) => void;
};

const CheckoutButton: FunctionComponent<CheckoutButtonProps> = ({
  isShopCampaign,
  isEditCampaign,
  isPublicShopping,
  displayEditCheckout,
  trackCheckout,
}) => {
  const { isNewMember } = useDataContext();
  const { hostname } = useInternalContext();
  const ctaText = getCheckoutCTAText(isEditCampaign, isNewMember);

  const redirectToCheckout = () => {
    let redirectLocation = null;
    if (isShopCampaign) {
      if (isPublicShopping) {
        redirectLocation = 'checkout';
      } else {
        redirectLocation = 'shop/checkout';
      }
    } else if (displayEditCheckout) {
      redirectLocation = 'edit/checkout';
    }
    if (redirectLocation) {
      window.location.href = `https://${hostname}/${redirectLocation}`;
    }
    return;
  };

  const handleCheckout = () => {
    trackCheckout(ctaText);
    redirectToCheckout();
  };

  return (
    <Button
      onClick={handleCheckout}
      styles={{ marginTop: 12, width: '100%' }}
      data-testid="checkout-button"
      data-e2e="checkout-button"
    >
      {ctaText}
    </Button>
  );
};

export default CheckoutButton;
