import { stringify } from 'query-string';
import {
  flattenProducts,
  getCartVariants,
  getCount,
  getUpdatedCartVariants,
  getVariantQuantityLimits,
} from '../utils/formatResponse';
import { getCampaignIdQueryParam } from '../utils/page';
import request from './request';

const campaignIdQueryParam = getCampaignIdQueryParam();

/**
 * Fetches the cart
 * @param {string} campaignType - The type of campaign
 * @param {boolean} previewCampaign - Whether the campaign is being previewed
 * @param {string} env - The environment
 * @param {boolean} isPublicShopping - Whether the cart is being fetched for public shopping
 * @returns {object} The cart details, cart variants, count, cart ID, and customize campaign details
 */
export async function fetchCart(campaignType, previewCampaign, env, isPublicShopping) {
  const queryParams = isPublicShopping
    ? { campaignType }
    : {
        type: campaignType,
        ...(campaignIdQueryParam && previewCampaign && { campaignId: campaignIdQueryParam }),
      };
  const endpoint = `${
    isPublicShopping ? 'public/api/' : 'api/'
  }1/carts/campaigns?${stringify(queryParams)}`;

  const response = await request(endpoint, 'GET', env);
  const {
    products: productsWithVariants,
    cartDetails,
    cartId,
    customizeCampaignDetails,
  } = response;
  const { products, variants } = flattenProducts(productsWithVariants);
  const cartVariants = getCartVariants(cartDetails, variants, products);
  const count = getCount(cartVariants);
  return { cartDetails, cartVariants, count, cartId, customizeCampaignDetails };
}

const handlePublicApiResponse = (response, campaignType, currentVariants) => {
  const { cartDetails, products: productsWithVariants, cartId } = response.value;
  let cartVariants;
  if (campaignType === 'SHOP') {
    // API response contains all the products in the cart for Shop
    const { products, variants } = flattenProducts(productsWithVariants);
    cartVariants = getCartVariants(cartDetails, variants, products);
  } else {
    cartVariants = getUpdatedCartVariants(currentVariants, cartDetails.variantQuantities);
  }
  const count = getCount(cartVariants);
  return {
    success: true,
    cartDetails,
    cartVariants,
    count,
    productsWithVariants,
    cartId,
  };
};

/**
 * Updates the cart
 * @param {string} variantId - The ID of the variant to update
 * @param {string} operation - The operation to perform
 * @param {string} campaignType - The type of campaign
 * @param {object[]} currentVariants - The current variants in the cart
 * @param {string} env - The environment
 * @param {string} cadenceType - The cadence type
 * @param {boolean} isPublicShopping - Whether the cart is being fetched for public shopping
 * @param {string} itemType - The type of item
 * @returns {object} The cart details, cart variants, count, products with variants, and cart ID
 */
export async function updateCart(
  variantId,
  operation,
  campaignType,
  currentVariants,
  env,
  cadenceType,
  isPublicShopping,
  itemType,
) {
  const queryParams = {
    operation,
    campaignType,
    ...(campaignIdQueryParam && { campaignId: campaignIdQueryParam }),
    ...(isPublicShopping && itemType && { itemType }),
    ...(cadenceType && { cadenceType }),
  };
  const endpoint = `${
    isPublicShopping ? 'public/api/' : 'api/'
  }1/carts/variants/${variantId}?${stringify(queryParams)}`;

  const response = await request(endpoint, 'POST', env);
  if (response.success) {
    const { cartDetails, products: productsWithVariants, cartId } = response.value;
    let cartVariants;
    if (campaignType === 'SHOP') {
      // API response contains all the products in the cart for Shop
      const { products, variants } = flattenProducts(productsWithVariants);
      cartVariants = getCartVariants(cartDetails, variants, products);
    } else {
      cartVariants = getUpdatedCartVariants(currentVariants, cartDetails.variantQuantities);
    }
    const count = getCount(cartVariants);
    return {
      success: true,
      cartDetails,
      cartVariants,
      count,
      productsWithVariants,
      cartId,
    };
  }
  return response;
}

export const getPurchasedVariants = async (campaignId, campaignType, cartVariants, env) => {
  const endpoint = `api/1/carts/campaigns/${campaignId}/purchases?type=${campaignType}`;
  const response = await request(endpoint, 'GET', env);
  return getVariantQuantityLimits(cartVariants, response.variantQuantities);
};

/**
 * Updates the membership
 * @param {string} operation - The operation to perform
 * @param {string} campaignType - The type of campaign
 * @param {object[]} currentVariants - The current variants in the cart
 * @param {string} env - The environment
 * @param {boolean} isPublicShopping - Whether the cart is being fetched for public shopping
 * @returns {object} The cart details, cart variants, count, products with variants, and cart ID
 */
export async function updateMembership(
  operation,
  campaignType,
  currentVariants,
  env,
  isPublicShopping,
) {
  if (isPublicShopping && operation === 'REMOVE') {
    const introVariants = currentVariants.filter((item) => item.variantType === 'INTRO');
    if (introVariants.length) {
      const endpoint =
        'public/api/1/carts/item-type/from/INTRO/to/PROSPECT_SINGLE?campaignType=SHOP';
      const response = await request(endpoint, 'POST', env);
      if (response.success) {
        return handlePublicApiResponse(response, campaignType, currentVariants);
      }
      return response;
    }
  }

  if (isPublicShopping && operation === 'ADD') {
    const endpoint = 'public/api/1/carts/item-type/from/PROSPECT_SINGLE/to/INTRO?campaignType=SHOP';
    const response = await request(endpoint, 'POST', env);
    if (response.success) {
      return handlePublicApiResponse(response, campaignType, currentVariants);
    }
    return response;
  }

  return null;
}
