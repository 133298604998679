import { updateCart, updateMembership } from '../apis/cartRequests';
import { flattenProducts } from './formatResponse';
import track, { segmentEvents } from './segment';

export const updateCartHandler = async (
  id,
  operation,
  campaignType,
  cartVariants,
  receiveUpdateCart,
  receiveCartProducts,
  env,
  setCartData,
  cadenceType,
  isPublicShopping,
  itemType, // only required for public shopping and operation type ADD
) => {
  try {
    const response = await updateCart(
      id,
      operation,
      campaignType,
      cartVariants,
      env,
      cadenceType,
      isPublicShopping,
      itemType,
    );
    if (response.success) {
      const {
        cartDetails: updatedCartDetails,
        cartVariants: updatedCartVariants,
        count,
        productsWithVariants,
        cartId,
      } = response;
      if (receiveCartProducts) {
        // if shop page -> update shop cart data in pandora
        // API response contains all the products in the cart for Shop
        const { products, variants } = flattenProducts(productsWithVariants);
        receiveCartProducts({ products, variants });
        receiveUpdateCart(id, updatedCartDetails, cartId);
      } else if (receiveUpdateCart) {
        // if sale page -> update sale cart data in pandora
        receiveUpdateCart(id, updatedCartDetails, cartId);
      } else {
        // update component state
        setCartData((prevState) => {
          return {
            ...prevState,
            cartDetails: updatedCartDetails,
            cartVariants: updatedCartVariants,
            itemsCount: count,
          };
        });
      }
    }
    return response;
  } catch (err) {
    return err;
  }
};

export const updateMembershipHandler = async (
  operation,
  campaignType,
  cartVariants,
  receiveUpdateCart,
  receiveCartProducts,
  env,
  setCartData,
  isPublicShopping,
) => {
  try {
    const response = await updateMembership(
      operation,
      campaignType,
      cartVariants,
      env,
      isPublicShopping,
    );
    if (response.success) {
      const {
        cartDetails: updatedCartDetails,
        cartVariants: updatedCartVariants,
        count,
        productsWithVariants,
        cartId,
      } = response;
      if (receiveCartProducts) {
        const { products, variants } = flattenProducts(productsWithVariants);
        receiveCartProducts({ products, variants });
        receiveUpdateCart(null, updatedCartDetails, cartId);
      } else if (receiveUpdateCart) {
        receiveUpdateCart(null, updatedCartDetails, cartId);
      } else {
        setCartData((prevState) => {
          return {
            ...prevState,
            cartDetails: updatedCartDetails,
            cartVariants: updatedCartVariants,
            itemsCount: count,
          };
        });
      }
    }
    return response;
  } catch (err) {
    return err;
  }
};

export const trackUpdateCart = (trackingData, cta, operation) => {
  let trackingDataWithCta;
  if (cta) {
    trackingDataWithCta = { ...trackingData, cta_name: cta };
  }
  if (operation === 'ADD') {
    track(segmentEvents.productAdded, trackingDataWithCta || trackingData);
  }
  if (operation === 'REMOVE') {
    track(segmentEvents.productRemoved, trackingDataWithCta || trackingData);
  }
};
