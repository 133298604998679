import { useEffect, useReducer } from 'react';
import { getMyBoxDetails, getMyBoxRate } from '../services/myBoxStatus';
import { actions, initialState, myBoxStatusReducer } from '../Store';

const { setMyBox, setMyBoxSuccess, setMyBoxFail } = actions;

const useMyBoxStatus = () => {
  const [{ myBox }, stateDispatch] = useReducer(myBoxStatusReducer, initialState());

  useEffect(() => {
    stateDispatch(setMyBox());
    getMyBoxDetails()
      .then((data) => {
        getMyBoxRate()
          .then((rateData) => {
            stateDispatch(
              setMyBoxSuccess({
                ...data,
                surveyFinished: rateData.surveyFinished,
                surveyStarted: rateData.surveyStarted,
                eligible: rateData.eligible,
              }),
            );
          })
          .catch(() =>
            stateDispatch(
              setMyBoxSuccess({
                ...data,
                surveyStarted: false,
                surveyFinished: true,
                eligible: false,
              }),
            ),
          );
      })
      .catch((error) => stateDispatch(setMyBoxFail(error)));
  }, []);

  return myBox;
};

export default useMyBoxStatus;
