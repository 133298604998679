import ErrorBoundary from './ErrorBoundary';
import { GlobalFooterDefault, type GlobalFooterDefaultProps } from './GlobalFooterDefault';

export const GlobalFooter = (props: GlobalFooterDefaultProps) => (
  <ErrorBoundary>
    <GlobalFooterDefault {...props} />
  </ErrorBoundary>
);

export default GlobalFooter;
