import { bool, func, node, oneOfType, shape, string } from 'prop-types';
import { trackElement } from '@fff-web/fff-analytics';
import { useMyBoxContext } from '../../context/MyBoxContext';
import ChallengeBoxDesktop from './ChallengeBoxDesktop';
import ChallengeBoxMobile from './ChallengeBoxMobile';

const ChallengeBox = ({
  flags,
  screenMode = 'mobile',
  showReviewButton = false,
  RightContent = null,
  showAddressDetails,
}) => {
  const cont = useMyBoxContext();
  const isMobile = ['mobile', 'tablet'].includes(screenMode);

  const {
    data: {
      imageURL,
      name,
      status = {
        id: '',
        title: '',
        body: '',
        bodyDetail: '',
        trackingUrl: '',
        customerServiceLink: false,
      },
      shippingAddress,
      order: { currentOrderStep, orderSteps } = {},
    },
    fail,
  } = cont.myBox;

  const wizmoMode = flags && flags.wizmoHomepage === true;
  const { id, trackingUrl } = status;
  const title = wizmoMode ? status.titleWizmo : status.title;
  const body = wizmoMode ? status.bodyWizmo : status.body;
  const bodyDetail = wizmoMode ? status.bodyDetail : '';

  const trackElements = (elementName) =>
    new Promise((resolve, reject) => {
      trackElement({ elementName, properties: { box_status: currentOrderStep } })
        .then(() => resolve(true))
        .catch(() => reject());
    });

  return isMobile ? (
    <ChallengeBoxMobile
      imageURL={imageURL}
      wizmoMode={wizmoMode}
      name={name}
      id={id}
      title={title}
      body={body}
      bodyDetail={bodyDetail}
      trackingUrl={trackingUrl}
      screenMode={screenMode}
      orderSteps={orderSteps}
      fail={fail}
      showReviewButton={showReviewButton}
      currentOrderStep={currentOrderStep}
      shippingAddress={shippingAddress}
      RightContent={RightContent}
      showServiceLink={status.customerServiceLink}
      trackElements={trackElements}
      showAddressDetails={showAddressDetails}
    />
  ) : (
    <ChallengeBoxDesktop
      imageURL={imageURL}
      wizmoMode={wizmoMode}
      name={name}
      id={id}
      title={title}
      body={body}
      bodyDetail={bodyDetail}
      trackingUrl={trackingUrl}
      screenMode={screenMode}
      orderSteps={orderSteps}
      fail={fail}
      showReviewButton={showReviewButton}
      currentOrderStep={currentOrderStep}
      shippingAddress={shippingAddress}
      RightContent={RightContent}
      showServiceLink={status.customerServiceLink}
      trackElements={trackElements}
      showAddressDetails={showAddressDetails}
    />
  );
};

ChallengeBox.propTypes = {
  screenMode: string,
  showReviewButton: bool,
  RightContent: oneOfType([node, func]),
  flags: shape,
  showAddressDetails: bool,
};

export default ChallengeBox;
