// extracted by mini-css-extract-plugin
export var add = "Banner_add__B+1Bg";
export var box = "Banner_box__TCAEJ";
export var close = "Banner_close__xwQ2h";
export var container = "Banner_container__3clyP";
export var content = "Banner_content__8nZPx";
export var description = "Banner_description__KREOf";
export var note = "Banner_note__0ZQFG";
export var shared = "\"../../../shared/shared.module.css\"";
export var sm = "576px";
export var title = "Banner_title__Ll1Pr";
export var wrapperClose = "Banner_wrapperClose__kaueH";