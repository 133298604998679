import { ReactNode } from 'react';
import { LinkShape } from '../../services/types';
import { buildPath } from '../../utils/buildPath';

interface Props {
  link: LinkShape;
  className?: string;
  children?: ReactNode;
  handleOnClick: (link: LinkShape, path: string) => void;
}

const LinkItem = ({ link, className, children, handleOnClick }: Props) => {
  const { route, subdomain, prodOnly, label } = link;
  const path = buildPath(route, subdomain, prodOnly);

  return (
    <a className={className} href={path} onKeyDown={() => handleOnClick(link, path)}>
      {children ?? label}
    </a>
  );
};

export { LinkItem };
