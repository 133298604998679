import { useEffect, useState } from 'react';
import { Compare, FFFStorage } from '@fff-web/fff-utilities';
import { getFooterContent } from '../services';
import { FooterContentResponseData } from '../services/types';

const footerContentKey = 'footer-content';
const cacheDurationInMs = 15 * 60 * 1000; // 15 minutes

const useFooterContent = () => {
  const [footerContent, setFooterContent] = useState<FooterContentResponseData>(
    FFFStorage.getFromStorage({ key: footerContentKey }),
  );

  useEffect(() => {
    const mountFooterContent = async () => {
      const footerCache = FFFStorage.getFromStorage({ key: footerContentKey });
      if (footerCache) {
        if (!Compare.isEqualObject(footerContent, footerCache)) {
          setFooterContent(footerCache);
        }
      } else {
        const footerContentResponse = await getFooterContent();

        setFooterContent(footerContentResponse);
        FFFStorage.setToStorage({
          key: footerContentKey,
          value: footerContentResponse,
          durationInMs: cacheDurationInMs,
        });
      }
    };

    mountFooterContent();
  }, [footerContent]);

  return { footerContent, defaultYear: new Date().getFullYear().toString() };
};

export default useFooterContent;
