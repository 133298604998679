import useLoadChat from '../../hooks/useLoadChat';

type Props = {
  showChatWidget: boolean;
};

function ChatWidget({ showChatWidget }: Props) {
  useLoadChat({ showChatWidget });

  return null;
}

export default ChatWidget;
