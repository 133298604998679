import classNames from 'classnames';
import './HamburgerMenuIcon.css';

type Props = {
  isOpen: boolean;
  onClick: () => void;
};

const HamburgerMenuIcon = ({ isOpen = false, onClick }: Props) => (
  <button
    type="button"
    data-testid="hamburger-menu-icon"
    className="hamburger-menu-icon"
    onClick={onClick}
  >
    <div className={classNames({ 'is-open': isOpen })}>
      <span />
      <span />
    </div>
  </button>
);

export default HamburgerMenuIcon;
